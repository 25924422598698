/** npm import */

import React, { useEffect, useState } from "react";

/** local files import */

import SalesCard from "../../components/SalesCard";
import Classes from "../../asset/styles/LaunchpadList.module.css";
import { Images } from "../../data/Images";
import CountdownSimple from "../../components/CountdownSimple";
import { commonData } from "../../data/CommonData";
import LaunchingTable from "../../components/LaunchingTable";
import Layout from "../../layout/Layout";
import BalanceDetails from "../../components/BalanceDetails";
import Countdown from 'react-countdown';
import { Col, Container, Row } from "react-bootstrap";
import Usesale from "../../hooks/useSale";
import { getallsalehook } from "../../actions/userAction";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { isEmpty } from "../../lib/isEmpty";
import PremiumCard from "./premiumCard";
import { searchsaleByToken } from "../../validations/userValidations";


const LaunchpadList = () => {
  const [tab, setTab] = useState("sale");
  const saleHook = Usesale()

  const [saleData, setSaleData] = useState({})
  const [myContribution, setMyContribution] = useState({})
  const [saleAdd, setSaleAdd] = useState("")
  const [saleDBData, setSaleDBData] = useState({})
  const [myDBData, setMyDBData] = useState({})
  const [interval, setInterval] = useState(1)
  const [loadInterval, setLoadInterval] = useState(3)
  const [loadData, setLoadData] = useState([])
  const [myLoadData, setMyLoadData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [ownerAdd, setOwnerAdd] = useState("")
  const [premium, setPremium] = useState({})
  const [isSearch, setIsSearch] = useState(false)
  const [searchSale, setSearchSale] = useState([])
  const [search, setSearch] = useState("")
  const [isSearching, setIsSearching] = useState(false)

  const walletdetail = useSelector((state) => state.wallet)
  console.log("walletdetail_launchLIst", walletdetail)

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className={`${Classes.countdownRenderer} d-flex justify-content-start align-items-center`}>
        <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{days < 10 ? `0${days}` : days}:</p>
        <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{hours < 10 ? `0${hours}` : hours}:</p>
        <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{minutes < 10 ? `0${minutes}` : minutes}:</p>
        <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{seconds < 10 ? `0${seconds}` : seconds}</p>
      </div>
    )
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getSaleInfo()
    getOwner()
  }, [ownerAdd, walletdetail?.walletaddress])


  const getOwner = async () => {
    const owner = await saleHook?.getSaleOwner()
    console.log("owner", owner)
    setOwnerAdd(owner)
  }

  const getSaleInfo = async () => {
    let TotalData = await saleHook?.UseTotalSaleInfo()
    console.log("getSale", TotalData)
    const premiumfilter = TotalData?.filter((val) => {
      console.log("premiumfilter_val", val?.account?.authority.toString(), val?.account?.authority.toString() == ownerAdd && val?.account?.isWhitelist == false);
      if (isEmpty(val?.account?.splStock) && val?.account?.ready == 1) {
        console.log("Deposit_check", walletdetail?.walletaddress, val?.account?.authority.toString(), val?.account?.authority.toString() == walletdetail?.walletaddress)
        return val?.account?.authority.toString() == ownerAdd && val?.account?.isWhitelist == false && val?.account?.authority.toString() == walletdetail?.walletaddress
      } else {
        return val?.account?.authority.toString() == ownerAdd && val?.account?.isWhitelist == false
      }
    })

    console.log("premiumfilter123", premiumfilter)
    setPremium(premiumfilter)
    const normalSale = TotalData.filter((val) => {
      if (isEmpty(val?.account?.splStock) && val?.account?.ready == 1) {
        console.log("Deposit_check", walletdetail?.walletaddress, val?.account?.authority.toString(), val?.account?.authority.toString() == walletdetail?.walletaddress)
        return val?.account?.isWhitelist == false && val?.account?.authority.toString() == walletdetail?.walletaddress
      } else {
        return val?.account?.isWhitelist == false
      }

    })
    setSaleData(normalSale)

    let getSale = [...normalSale]
    let limit = [...getSale].splice(0, loadInterval)
    console.log("getSale", getSale, limit)
    setLoadData(limit)

    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb", getSalefromDb)
    setSaleDBData(getSalefromDb?.data?.data)
  }

  const getMyContribution = async () => {

    const getSale = await saleHook?.UseTotalSaleInfo()
    console.log("getSale_contribution", getSale)
    const Totalfilter = getSale?.filter((val) => {
      return val?.account?.authority.toString() == walletdetail?.walletaddress && val?.account?.isWhitelist == false
    })
    console.log(Totalfilter, "getMyContribution")
    let filter = [...Totalfilter]
    setMyContribution(filter)
    let limit = [...filter].splice(0, loadInterval)
    setMyLoadData(limit)

    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb", getSalefromDb)
    let DBPush = []
    for (let i = 0; i < filter.length; i++) {
      const filterDb = getSalefromDb?.data?.data?.find((val) => (val?.saleAddress == filter[i]?.publicKey?.toString()))
      console.log(filterDb, "getMyContribution2", filter[i]?.publicKey?.toString())
      DBPush.push(filterDb)
    }
    setMyDBData(DBPush)

  }

  const loadMore = async (interval) => {
    console.log("Load_moreee", saleData, interval)
    setIsLoading(true);
    setInterval(interval)
    let skip = (interval - 1) * loadInterval
    const newly = [...saleData];
    const normalSale = newly.filter((val) => {
      return val?.account?.isWhitelist == false
    })
    const limit = normalSale.splice(skip, loadInterval)
    console.log("newly ", newly, limit);
    setIsLoading(false);
    setLoadData([...loadData, ...limit])
  }


  const loadMyContribution = async (interval) => {
    console.log("loadMyContribution", myContribution, interval)
    setIsLoading(true);
    setInterval(interval)
    let skip = (interval - 1) * loadInterval
    const newly = [...myContribution];
    const normalSale = newly.filter((val) => {
      return val?.account?.isWhitelist == false
    })
    const limit = normalSale.splice(skip, loadInterval)
    console.log("newly ", newly, limit);
    setIsLoading(false);
    setMyLoadData([...myLoadData, ...limit])
  }

  const SearchByToken = async (searchValue) => {
    setIsSearching(true)
    if (!isEmpty(searchValue)) {
      let data = searchsaleByToken(saleDBData, searchValue);
      console.log("setisSearchsetisSearch", data, loadData?.account?.splMint.toString());
      let loaddata = loadData?.filter((val) => {
        return data.includes(val.account.splMint.toString())
      })
      console.log("loaddata", loaddata)
      setLoadData(loaddata)
    } else {
      let skip = (interval) * loadInterval
      const newly = [...saleData];
      const normalSale = newly.filter((val) => {
        return val?.account?.isWhitelist == true
      })
      const limit = normalSale.splice(0, skip)
      console.log("newly ", newly, limit);
      setLoadData([...limit])
    }
    // setSaleDBData(data)
  }
  console.log(loadData, 'loadData')


  return (
    <>
      <Layout>
        <div className={``}>
          {console.log("loadData", loadData?.length)
          }
          <section className="custom_section position-relative">
            <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />


            <BalanceDetails />


            <Row className={`mx-auto position-relative`}>
              <Col xs={12} lg={{ span: 8, offset: 2 }} className={`px-lg-0`}>
                <div className={premium?.length > 0 ? `${Classes.catImgBg} position-relative` : `${Classes.catImgBg} ${Classes.heightnodata} position-relative`}>
                  <img src={Images.clip} className={`img-fluid ${Classes.leftTopClip}`} alt="" />
                  <img src={Images.clip} className={`img-fluid ${Classes.leftBottomClip}`} alt="" />
                  <img src={Images.clip} className={`img-fluid ${Classes.rightTopClip}`} alt="" />
                  <img src={Images.clip} className={`img-fluid ${Classes.rightBottomClip}`} alt="" />

                  {premium && premium?.length > 0 ? premium?.map((val, i) => {
                    console.log("premium___val", val)
                    if (i == 0) {
                      let premiumLaunch = val?.account
                      let DBData = {}
                      if (saleDBData?.length > 0 && val?.account?.isWhitelist == false) {
                        DBData = saleDBData?.find((items) => (items?.saleAddress == val?.publicKey?.toString()))
                        console.log("DBData_premium", DBData)
                      }
                      return (
                        <PremiumCard DBData={DBData} premiumLaunch={premiumLaunch} val={val} />

                      )
                    }

                  }) :
                    <p className={`${Classes.yourBalance} text-center d-flex align-items-center justify-content-center text-uppercase roboto`}>Currently Premium sale is not available</p>
                  }
                </div>
              </Col>
            </Row>

            <Container fluid className={`p-0`}>
              <Row className={`mx-auto`}>
                <Col xs={{ span: 8, offset: 2 }} className={`${Classes.borderConnect}`}>

                </Col>
              </Row>
            </Container>

            <LaunchingTable />
            <Container className={`custom_container mt-5`}>
              <div className={`${Classes.launchPad__section}`}>
                <h2
                  className={`${Classes.launchpad__title} text-uppercase aboro_font`}
                >
                  Token sale launchpad
                </h2>

                <Row className={`mt-3`}>
                  <Col lg={5} className={``}>
                    <div
                      className={`${Classes.launchpad__dualBox} d-flex justify-content-center align-items-center`}
                    >
                      <p
                        className={`${Classes.dualBox_hint} inter_font text-uppercase w-75  m-0 mt-5`}
                      >
                        Gain early access to public and special token sales at a lower
                        price before they hit the market
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row className={`mt-5`}>
                  <Col md={5} lg={6} className={`mb-4 mb-md-0`}>
                    <div
                      className={`d-flex justify-content-start align-items-center`}
                    >
                      <p
                        className={`${tab === "sale"
                          ? Classes.tab__titleActive
                          : Classes.tab__title
                          } m-0 text-center px-3 me-2 pointer poppins`}
                        onClick={() => setTab("sale")}
                      >
                        All Sale
                      </p>
                      <p
                        className={`${tab === "contribution"
                          ? Classes.tab__titleActive
                          : Classes.tab__title
                          } m-0 text-center px-3 pointer poppins`}
                        onClick={() => { getMyContribution(); setTab("contribution") }}
                      >
                        My Contribution
                      </p>
                    </div>
                  </Col>
                  <Col md={7} lg={6} className={``}>
                    <div
                      className={`d-flex justify-content-end align-items-center w-100`}
                    >
                      <div className={`${Classes.searchBtn}`}>
                        <p className={`${Classes.searchHint} d-none d-sm-flex poppins`}>Search Token</p>
                        <p className={`${Classes.searchHint} d-sm-none`}><i class="fa-solid fa-magnifying-glass" /></p>

                      </div>
                      <input
                        type="text"
                        className={`${Classes.searchBar} flex-grow-1 poppins`}
                        onChange={(e) => {
                          SearchByToken(e.target.value)

                        }}
                      />
                    </div>
                  </Col>
                </Row>
                {tab == "sale" ?
                  <Row className={`mt-5`}>

                    {saleDBData && loadData?.length > 0 ?
                      loadData?.map((items, index) => {
                        console.log("items", items, saleDBData)
                        let DBData = {}
                        if (saleDBData?.length > 0
                          && items?.account?.isWhitelist == false
                        ) {
                          DBData = saleDBData?.find((val) => (val?.saleAddress == items?.publicKey?.toString()))
                          console.log("DBData", DBData)
                        }
                        return (
                          (items?.account?.isWhitelist == false &&
                            <>
                              <SalesCard saleData={items} saleDBData={DBData} />
                            </>
                          )
                        )
                      })

                      : <p className="text-white text-center roboto mt-4">No Data Found</p>

                    }
                    {saleData?.length > loadData?.length ?
                      <div
                        className={`d-flex justify-content-center align-items-center w-100 mt-5`}>
                        <button
                          className={`${Classes.launchpad__saleViewBtn} text-uppercase roboto`}
                          onClick={() => { loadMore(interval + 1) }}
                        >
                          {isLoading ? 'Load More...' : 'Load More'}
                        </button>
                      </div> : <div></div>}


                  </Row>
                  :

                  <Row className={`mt-5`}>

                    {myDBData && myLoadData?.length > 0 ?
                      myLoadData?.map((items, index) => {
                        console.log("items", items, myDBData)
                        let DBData = {}
                        if (myDBData?.length > 0
                          && items?.account?.isWhitelist == false
                        ) {
                          DBData = myDBData?.find((val) => (val?.saleAddress == items?.publicKey?.toString()))
                          console.log("DBData", DBData)
                        }
                        return (
                          (
                            items?.account?.isWhitelist == false &&
                            <>
                              <SalesCard saleData={items} saleDBData={DBData} />
                            </>
                          )
                        )
                      })

                      : <p className="text-white text-center roboto  mt-4">No Data Found</p>

                    }

                    {myContribution?.length > myLoadData?.length ?

                      <div
                        className={`d-flex justify-content-center align-items-center w-100 mt-5`}>
                        <button className={`${Classes.launchpad__saleViewBtn} text-uppercase roboto`}
                          onClick={() => { loadMyContribution(interval + 1) }}>{isLoading ? 'Load More...' : 'Load More'}</button>
                      </div>
                      : <div>
                      </div>
                    }


                  </Row>
                }

              </div>
            </Container>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default LaunchpadList;
