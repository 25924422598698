import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Classes from "../../asset/styles/LaunchpadList.module.css";
import { isEmpty } from "../../lib/isEmpty";
import { Images } from "../../data/Images";
import Usetoken from "../../hooks/useToken";
import Countdown from 'react-countdown';
import Usesale from "../../hooks/useSale";




const PremiumCard = ({DBData,premiumLaunch,val}) => {

     const tokenHook = Usetoken()
     const saleHook = Usesale()

     const [tokeninfo, setTokeninfo] = useState({})

     console.log("PremiumCard",DBData,premiumLaunch,val,premiumLaunch?.splMint.toString())

     const renderer = ({ days, hours, minutes, seconds }) => {
        return (
          <div className={`${Classes.countdownRenderer} d-flex justify-content-start align-items-center`}>
            <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{days < 10 ? `0${days}` : days}:</p>
            <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{hours < 10 ? `0${hours}` : hours}:</p>
            <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{minutes < 10 ? `0${minutes}` : minutes}:</p>
            <p className={`${Classes.card__counterSimple} m-0 position-relative`}>{seconds < 10 ? `0${seconds}` : seconds}</p>
          </div>
        )
      }


    useEffect(()=>{
        getTokenInfo()
      },[premiumLaunch?.splMint])
    
      const getTokenInfo = async () => {
        console.log("tokeninfoooo",premiumLaunch?.splMint)
        if (!isEmpty(premiumLaunch?.splMint)) {
          // let tokenInfo = await tokenHook.getTokenbalance(walletdetail?.walletaddress,  saleInfo?.splMint)
          let tokenInfo = await tokenHook.getTokenNameSymbol(premiumLaunch?.splMint)
          console.log("tokenInfo", tokenInfo)
          setTokeninfo(tokenInfo)
        }
      }


  return (
    <>

<Row className={``}>
<Col md={8} xl={9} className={``}>
  <div className={`p-4`}>
    <div className={`d-flex justify-content-between align-items-start gap-4 flex-wrap`}>
      <div className={`${Classes.launchpad__nameHolder}`}>

        <h1
          className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>{tokeninfo?.symbol ? tokeninfo?.symbol :'MEKO'}</h1>
      </div>

      <div className={`d-flex justify-content-end align-items-center gap-3`}>
        <Link to={DBData?.website} target="_blank"> <img src={Images.globe} className={`img-fluid pointer`} /></Link>
        <Link to={DBData?.twitter} target="_blank"> <img src={Images.twitter} className={`img-fluid pointer`} /></Link>
        <Link to={DBData?.telegram} target="_blank">  <img src={Images.telegram} className={`img-fluid pointer`} /></Link>
        {/* <img src={Images.globe} className={`img-fluid pointer`} />
    <img src={Images.twitter} className={`img-fluid pointer`} />
    <img src={Images.telegram} className={`img-fluid pointer`} /> */}
      </div>
    </div>

    <p className={`${Classes.launchpad__desc} ${Classes.responsiveWidText} m-0 text-uppercase mt-4 roboto`}>
      {DBData?.description}
      {/* Meme of Neko is not only meme project on solana, we also provide NFT Staking. Staking NFT link : https://nft.mekosolana.com. Buy presale, Earn passive income by staking your Meko NFT in our platform. */}
    </p>

    <div className={`${Classes.flexHolder} pt-2`}>
      <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
        <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>token name :</p>
        <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>{tokeninfo?.name ? tokeninfo?.name :'Meme of Neko'}</p>
      </div>
      <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
        <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>symbol :</p>
        <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>{tokeninfo?.symbol ? tokeninfo?.symbol :'MEKO'}</p>
      </div>
      <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
        <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>total supply :</p>
        <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}> {tokeninfo?.tokenBalance ? tokeninfo?.tokenBalance :'10,000,000,000'} </p>
      </div>
      <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
        <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>status :</p>
        {premiumLaunch && saleHook.isUpcoming(parseInt(premiumLaunch?.presaleStart)) ?
          <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>coming up</p>
          :
          premiumLaunch && saleHook.isSaleLive(premiumLaunch?.presaleStart, premiumLaunch?.presaleEnd) ?
            <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>Live</p>
            :
            <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>Ended</p>


        }

      </div>
      <div className={`mt-4 d-flex justify-content-start align-items-center gap-3`}>
        <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>countdown :</p>
        {/* <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>
      <CountdownSimple initialHours={initialHours} initialMinutes={initialMinutes} initialSeconds={initialSeconds} initialDays={initialDays} /> */}
        {/* <Countdown date={Date.now() + 2000000} renderer={renderer} />  */}

        {premiumLaunch && saleHook.isUpcoming(parseInt(premiumLaunch?.presaleStart)) ?
          <>
            <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>
              <Countdown date={Date.now() + saleHook.Salediffernce(parseInt(premiumLaunch?.presaleStart))} renderer={renderer} />
            </p>
          </>
          :
          premiumLaunch && saleHook.isSaleLive(premiumLaunch?.presaleStart, premiumLaunch?.presaleEnd) ?
            <>
              <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>
                <Countdown date={Date.now() + saleHook.Salediffernce(parseInt(premiumLaunch?.presaleEnd))} renderer={renderer} />
              </p>
            </>
            :
            <p className={`${Classes.launchpad__desc} ${Classes.widthAligedText} m-0 text-uppercase roboto`}>Sale ended</p>

        }
        {/* </p> */}
      </div>
    </div>

    <NavLink to={`/premium-sale-detail/${val && (val?.publicKey)?.toString()}`}
      onClick={() => {
        sessionStorage.setItem("saledata", JSON.stringify(val));
        sessionStorage.setItem("saleDBdata", JSON.stringify(DBData))
      }}>
      <button className={`${Classes.launchpad__saleViewBtn} text-uppercase roboto mt-5`}>
        sale view
      </button>
    </NavLink>

  </div>
</Col>

<Col md={4} xl={3} className={`col-xl-3 col-md-4`}>
  <div className={`d-flex justify-content-between flex-column flex-md-column flex-sm-row align-items-start`}>
    <img src={DBData?.logo ? DBData?.logo : Images.cat} className={`img-fluid ${Classes.cattyImg} flex-grow-1`} />


    <ul className={`${Classes.salesCard__unorderList} flex-grow-1 w-100 mb-0`}>
      <li className={`${Classes.salesCard__lister} py-2 px-3`}>
        <div className={`d-flex justify-content-between align-items-center`}>
          <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
          <p className={`${Classes.listText} roboto`}>Premium Gem</p>
        </div>
      </li>
      {!isEmpty(DBData?.kyc) &&
        <li className={`${Classes.salesCard__lister} py-2 px-3`}>
          <div className={`d-flex justify-content-between align-items-center`}>
            <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
            {/* <p className={`${Classes.listText} roboto text-uppercase`}>KYC</p> */}
            <NavLink className="linkset" to={DBData?.kyc} target="_blank"> KYC
            </NavLink>
          </div>
        </li>
      }
      {!isEmpty(DBData?.audit) &&
        <li className={`${Classes.salesCard__lister} py-2 px-3`}>
          <div className={`d-flex justify-content-between align-items-center`}>
            <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
            {/* <p className={`${Classes.listText} roboto`}>Audit</p> */}
            <NavLink className="linkset" to={DBData?.audit} target="_blank"> Audit
            </NavLink>
          </div>
        </li>
      }
      {!isEmpty(DBData?.SAFU) &&
        <li className={`${Classes.salesCard__lister} py-2 px-3`}>
          <div className={`d-flex justify-content-between align-items-center`}>
            <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
            {/* <p className={`${Classes.listText} roboto text-uppercase`}>safu</p> */}
            <NavLink className="linkset" to={DBData?.SAFU} target="_blank"> Safu
            </NavLink>
          </div>
        </li>
      }
      {!isEmpty(DBData?.DOXX) &&
        <li className={`${Classes.salesCard__lister} py-2 px-3`}>
          <div className={`d-flex justify-content-between align-items-center`}>
            <img src={Images.triangle} className={`img-fluid ${Classes.rotatedArrow}`} />
            {/* <p className={`${Classes.listText} roboto`}>Doxx</p>
           */}
            <NavLink className="linkset" to={DBData?.DOXX} target="_blank"> Doxx
            </NavLink>
          </div>
        </li>
      }
    </ul>
  </div>
</Col>
</Row>

</>
  )
};

export default PremiumCard;
