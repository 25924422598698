export const Searchfn = (data, Search, searchKeys) => {
    try {
        console.log(data, "Searchfn")
        let search = new RegExp(Search, 'i');
        let results = data.filter((val) => {
            for (const key of searchKeys) {
                console.log(val[`${key}`], 'val[`${key}`]')
                return val[`${key}`].match(search)
            }
        })
        return results
    } catch (err) {
        console.log(err, 'search___err')
    }
}