import React, { useEffect, useState } from 'react'
import AdminLayout from '../../layout/AdminLayout'
import Classes from '../../asset/styles/AdminLaunchpadSettings.module.css'
import { Col, Row } from 'react-bootstrap'
import { editLaunchpadFee, getLaunchpadFee } from '../../actions/adminAction'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import HookFunction from '../../hooks/useStorage'
import Usesale from '../../hooks/useSale'

const AdminLaunchpadSettings = () => {


  const [feeData, setfeeData] = useState({})

  const [poolCreationFee, setpoolCreationFee] = useState("0")
  const [LPCreationFee, setLPCreationFee] = useState("0")
  const [TokenCreationFee, setTokenCreationFee] = useState("0")
  const [ownerAdd, setOwnerAdd] = useState("")
  const walletdetail = useSelector((state) => state.wallet)
  const storageHook = HookFunction()
  const saleHook = Usesale()

  const getOwner = async () => {
    const owner = await saleHook?.getSaleOwner()
    console.log("owner", owner)
    setOwnerAdd(owner)
  }



  useEffect(() => {

    getOwner()
  }, [])


  useEffect(() => {
    storageHook.getWalletAddress()

  }, [walletdetail])


  useEffect(() => {
    LaunchpadFee()
  }, [])

  const LaunchpadFee = async () => {
    let feeData = await getLaunchpadFee();

    if (feeData.length > 0) {

      setfeeData(feeData)
      setpoolCreationFee(feeData[0]?.FeeAmount)
      setLPCreationFee(feeData[1]?.FeeAmount)

      setTokenCreationFee(feeData[2]?.FeeAmount)

    }
    console.log("feeData", feeData);

  }



  const EditLaunchpadFee = async (id, name, FeeAmount) => {
    let editdata = {
      id: id,
      feeName: name,
      FeeAmount: FeeAmount

    }

    let result = await editLaunchpadFee(editdata)
    if (result?.data?.data?.status) {
      toast.success(`${result?.data?.data?.message}`)
    } else {
      toast.error(`${result?.data?.data?.message}`)
    }
    console.log("resultresultresult", result?.data?.data?.message);

  }
  return (
    <>
      <AdminLayout>


        {ownerAdd !== walletdetail?.walletaddress ?
          <div className={`${Classes.noteset} text-white poppins `}>
            Note* : Only contract owner can edit fee settings
          </div> :
          <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
            <h2
              className={`${Classes.launchpad__title} text-uppercase aboro_font`}
            >
              Launchpad Settings
            </h2>
            <Row>
              <Col lg={8} xl={6}>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="poolCreationFee" className={`${Classes.inputLabel} orbitron`}>Pool Createion Fee</label><br />
                  <div className={`${Classes.inputHolder} mt-3 d-flex justify-content-between align-items-center`}>
                    <input type="number" id="poolCreationFee"
                      value={poolCreationFee}
                      name="poolCreationFee" className={`${Classes.inputOnly} inter_font flex-grow-1 w-100`}
                      placeholder='Ex.0' autoComplete="off"
                      onChange={(e) => setpoolCreationFee(e.target.value)} />
                    <button className={`${Classes.customGradBtn} border-0 outline-0 `} onClick={() => EditLaunchpadFee(feeData[0]?._id, feeData[0]?.feeName, poolCreationFee)}>Update</button>
                  </div>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="liquidityCreationFee" className={`${Classes.inputLabel} orbitron`}>Liquidity Token Creation Fee</label><br />
                  <div className={`${Classes.inputHolder} mt-3 d-flex justify-content-between align-items-center`}>
                    <input type="number"
                      value={LPCreationFee}

                      id="liquidityCreationFee" name="liquidityCreationFee" className={`${Classes.inputOnly} inter_font flex-grow-1 w-100`} placeholder='Ex.0' autoComplete="off"
                      onChange={(e) => setLPCreationFee(e.target.value)} />

                    <button className={`${Classes.customGradBtn} border-0 outline-0 `} onClick={() => EditLaunchpadFee(feeData[1]?._id, feeData[1]?.feeName, LPCreationFee)}>Update</button>
                  </div>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="tokenCreationFee" className={`${Classes.inputLabel} orbitron`}>Token Creation Fee</label><br />
                  <div className={`${Classes.inputHolder} mt-3 d-flex justify-content-between align-items-center`}>
                    <input type="number"
                      value={TokenCreationFee}
                      id="tokenCreationFee" name="tokenCreationFee" className={`${Classes.inputOnly} inter_font flex-grow-1 w-100`} placeholder='Ex.0' autoComplete="off"
                      onChange={(e) => setTokenCreationFee(e.target.value)} />
                    <button className={`${Classes.customGradBtn} border-0 outline-0 `} onClick={() => EditLaunchpadFee(feeData[2]?._id, feeData[2]?.feeName, TokenCreationFee)}>Update</button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        }
      </AdminLayout>
    </>
  )
}

export default AdminLaunchpadSettings
