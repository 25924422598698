/* for Get Program Instance NPM */
import * as anchor from '@project-serum/anchor';
import axios from 'axios'
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Core Solana NPM funtions */
import {
  Connection,
  LAMPORTS_PER_SOL,
  Transaction,
  clusterApiUrl,
  PublicKey,
  Keypair,
  TransactionInstruction,
} from "@solana/web3.js";

/* Solana Wallet Adapters */
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter,
} from '@solana/wallet-adapter-wallets';

/* Solana Mobile wallet adapter */
import {
  SolanaMobileWalletAdapter,
  createDefaultAuthorizationResultCache,
  createDefaultAddressSelector,
  createDefaultWalletNotFoundHandler,
} from "@solana-mobile/wallet-adapter-mobile";

/* Solana Math Wallet connect Adapter */
import { WalletConnectWalletAdapter } from '@solana/wallet-adapter-walletconnect';

/* storage */
// import { SetWallet, SetWalletAddress, SetWalletBal, getWalletAddress } from './useStorage';
import HookFunction from "./useStorage"

/* constants */

import { WALLET_ADDRESS, WALLET_DATA } from '../constants';
import Config from '../config';

const { SystemProgram } = anchor.web3;

var provider;
var programInstance;

export default function UseWallet() {
  const network = Config.NETWORK;
  const NETWORK_URL = Config.NETWORK_URL
  // const walletdetail = useSelector((state) => state.wallet)
  // console.log("walletdetail_UseWallet", walletdetail)
  const { wallet, publicKey } = useWallet()
  console.log("useSale_accountInfo", network, wallet, publicKey, wallet?.adapter?.name)

  const storageHooks = HookFunction()

  function isMobileOrTablet() {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
  }


  const getTransactionstatus = async (tx) => {
    const connection = new Connection(clusterApiUrl(network), "confirmed");
    const result = await connection.getSignatureStatus(tx, { searchTransactionHistory: true, });
    console.log("🚀 ~ getTransactionstatus ~ result:", result)
    return (result?.value?.confirmationStatus === 'confirmed')
  }



  /**
   * @function Walletconncet
   * @param {data}  
   * @returns {walletAdapter,Address,Sol}
   */

  const Walletconnect = async (data, dispatch) => {
    console.log("data", data)
    const networks = clusterApiUrl(network)
    try {
      let walletAdapter;
      if (data === "math") {
        if (isMobileOrTablet()) {

          /* Mobile Walletconncet */
          walletAdapter = new WalletConnectWalletAdapter({
            networks,
            options: {
              relayUrl: 'wss://relay.walletconnect.com',
              projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0',
              metadata: {
                name: 'Example App', // App Name
                description: 'Example App', // App Description
                url: 'https://200.140.70.236:3001/walcon', // App Frontend URL
                icons: ['https://avatars.githubusercontent.com/u/35608259?s=200'], // App Logo
              },
            },
          })
        } else {
          /* Math wallet Extension */
          walletAdapter = new MathWalletAdapter({ networks })
        }
      }
      if (data === "mobile") {
        /* Solana Mobile wallet Adapter (panthom , solfare) */
        walletAdapter = new SolanaMobileWalletAdapter({
          addressSelector: createDefaultAddressSelector(),
          appIdentity: {
            name: 'My app', //App Name
            uri: 'https://200.140.70.236:3001/walcon', //App
            icon: 'public/logo512.png',
          },
          authorizationResultCache: createDefaultAuthorizationResultCache(),
          cluster: network,
          onWalletNotFound: createDefaultWalletNotFoundHandler(),
        });
      }
      if (data === "Phantom") {
        /* Panthom wallet adapter */
        walletAdapter = new PhantomWalletAdapter()
      }
      if (data === "solfare") {
        /* Solfare wallet adapter */
        walletAdapter = new SolflareWalletAdapter({ network: network })
      }

      /* Wallet connections */
      await walletAdapter.connect();
      const connection = new Connection(clusterApiUrl(network), "confirmed");
      console.log("connection", connection)
      let bal = await connection.getBalance(walletAdapter.publicKey)
      provider = walletAdapter
      console.log("walletData", walletAdapter, walletAdapter.name, walletAdapter?.publicKey.toString(), (bal / LAMPORTS_PER_SOL))

      /*Set wallet data in session storage */
      storageHooks.SetWallet(walletAdapter.name)
      storageHooks.SetWalletAddress(walletAdapter?.publicKey.toString())
      storageHooks.SetWalletBal(bal / LAMPORTS_PER_SOL)

      let walletData = {
        walletName: walletAdapter.name,
        walletBal: bal / LAMPORTS_PER_SOL
      }

      /*redux*/
      dispatch({
        type: WALLET_ADDRESS,
        payload: walletAdapter?.publicKey.toString()
      });
      dispatch({
        type: WALLET_DATA,
        payload: walletData
      });

      return {
        walletAdapter,
        Address: walletAdapter?.publicKey.toString(),
        Sol: (bal / LAMPORTS_PER_SOL)
      }
    } catch (error) {

      if (data === "math") {
        let walletAdapter = new MathWalletAdapter({ network })
        if (walletAdapter.readyState === 'NotDetected') {
          window.alert("Math Wallet not Detected , make sure panthom is not exist")
        } else {
          window.alert("Make Sure youre Wallet was unlocked")
        }
        return {}
      }
    }
  }


  /**
   * @function getTokenbalance
   * @param {walletAddress , tokenAddress}
   * @returns {tokenBalance,Decimal}
   */

  const getTokenbalance = async (walletAddress, tokenAddress) => {
    let tokenBalance = 0;
    let Decimal = 0;
    const response = await axios({
      url: NETWORK_URL,   //`https://api.devnet.solana.com/`, // devnet URL or mainnet URL
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        jsonrpc: "2.0",
        id: 1,
        method: "getTokenAccountsByOwner",
        params: [
          walletAddress, // account addrss
          {
            mint: tokenAddress, // token mint address
          },
          {
            encoding: "jsonParsed",
          },
        ],
      },
    });
    console.log("🚀 ~ getTokenbalance ~ response:", response);
    if (
      Array.isArray(response?.data?.result?.value) &&
      response?.data?.result?.value?.length > 0 &&
      response?.data?.result?.value[0]?.account?.data?.parsed?.info?.tokenAmount
        ?.amount > 0
    ) {
      tokenBalance = Number(
        response?.data?.result?.value?.length > 0 &&
        response?.data?.result?.value[0]?.account?.data?.parsed?.info
          ?.tokenAmount?.uiAmount
      );
      Decimal =
        response?.data?.result?.value[0]?.account?.data?.parsed?.info
          ?.tokenAmount?.decimals;
    }
    return { tokenBalance, Decimal };
  };

  /**
 * @function getSolbalance
 * @param {walletAddress}
 * @returns {solBalance}
 */

  const getSolBalance = async (walletAddress) => {
    const connection = new Connection(clusterApiUrl(network), "confirmed");
    console.log("connection", connection)
    let bal = await connection.getBalance(walletAddress)
    console.log("balanceSol", bal)
    return bal
  }


  const UseProvider = () => {

    const connection = new Connection(clusterApiUrl(network), "confirmed");
    console.log("connection", connection)

    const anchorProvider = new anchor.AnchorProvider(connection, wallet?.adapter, {
      preflightCommitment: 'confirmed',
    });
    console.log("anchorProvider", anchorProvider)
    return anchorProvider
  }


  const stringToArrayBuffer = (string) => {
    // Way 1 
    // let byteArray = new Uint8Array(string.length);
    // for(var i=0; i < string.length; i++) {
    //   byteArray[i] = string.codePointAt(i);
    // }
    // return byteArray;

    //  way 2  
    const binaryString = atob("s9RmLhuwRk9aStBQpwgNr3tXZZjAQFvMTzLHyyGgHCFzuKQ9nktcNLK2m9BT9qztxJjwXYciqFSfedYrfXaPjf8");
    return new Uint8Array([...binaryString].map((char) => char.charCodeAt(0)));
  };

  const arrayBufferToString = (buf) => {
    let uint8Array = new Uint8Array(buf);
    return btoa(String.fromCharCode(...uint8Array));
  };


  return {
    getTransactionstatus,
    Walletconnect,
    getTokenbalance,
    getSolBalance,
    UseProvider,
    stringToArrayBuffer,
    arrayBufferToString
  }
}