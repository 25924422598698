import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Images } from '../../data/Images'
import Classes from '../../asset/styles/MyLockDetail.module.css'
import { Col, Row } from 'react-bootstrap'
import BalanceDetails from '../../components/BalanceDetails'
import LaunchingTable from '../../components/LaunchingTable'
import Countdown from 'react-countdown';
import TransferOwnerShip from '../../Modals/TransferOwnerShip'
import { useParams } from "react-router-dom";
import Uselock from '../../hooks/useLock'
import Usetoken from "../../hooks/useToken";
import { isEmpty } from '../../lib/isEmpty';
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';


const MyLockDetail = () => {
  const params = useParams()
  const lockHook = Uselock()
  const tokenHook = Usetoken()

  const [unlock, setUnlock] = useState(true)
  const [lockAdd, setLockAdd] = useState('')
  const [lockInfo, setLockInfo] = useState({})
  const [tokeninfo, setTokeninfo] = useState({})
  const [islock, setIslock] = useState(false)

  // modal state
  const [showTransferOwner, setTransferOwner] = useState(false)
  const handleShowTransfer = () => setTransferOwner(true)
  const handleCloseTransfer = () => setTransferOwner(false)

  const walletdetail = useSelector((state) => state.wallet)
  console.log("walletdetail_TokenLock", walletdetail, walletdetail?.walletData?.walletBal)


  const getLockData = async () => {
    let datas = await lockHook?.UseLockInfo(params?.lockAddress)
    console.log("getLock_LockDetail", datas, datas?.unlockOn.toString(), new Date(lockInfo?.unlockOn?.toString()))
    setLockAdd(params?.saleAddress)
    setLockInfo(datas)
  }

  useEffect(() => {
    getLockData()
  }, [])


  const getTokenInfo = async () => {
    console.log("tokeninfoooo", lockInfo?.splMint)
    if (!isEmpty(lockInfo?.splMint)) {
      let tokenInfo = await tokenHook.getTokenNameSymbol(lockInfo?.splMint)
      console.log("tokenInfo", tokenInfo)
      setTokeninfo(tokenInfo)
    }
  }

  useEffect(() => {
    getTokenInfo()
  }, [lockInfo?.splMint])


  const unLockTokens = async () => {
    setIslock(true)
    let lockData = {
      lockamount: lockInfo?.amountLocked,
      tokenAddress: lockInfo?.splMint,
      receiverAddress: lockInfo?.reciever,
      lockAddress: params?.lockAddress
    }
    console.log("createunLockTokens", lockData)
    var result = await lockHook.UseUnlockToken(lockData)
    console.log("result", result)
    setIslock(false)
    getTokenInfo()
    getLockData()
    if (result?.status == true) {
      toast.success('Tokens unlocked Successfully..!')
      // navigate(`/mylock-detail/${result?.lock}`)
      // setLockAdd(result?.lock)
    }
    else {
      toast.error('Error while unlocking token..!')
    }

  }

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className={`${Classes.countdownRenderer} d-flex justify-content-center align-items-center`}>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>
          {days < 10 ? `0${days}` : days}
          <span className='text-center'>Days</span>
        </p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>
          {hours < 10 ? `0${hours}` : hours}
          <span className='text-center'>Hrs</span>
        </p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>
          {minutes < 10 ? `0${minutes}` : minutes}
          <span className='text-center'>Mins</span>
        </p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 position-relative roboto`}>
          {seconds < 10 ? `0${seconds}` : seconds}
          <span className='text-center'>Secs</span>
        </p>
      </div>
    )
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Layout>
        <section className="custom_section position-relative">
          <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
          <BalanceDetails />

          <Row className={`mx-auto ${Classes.createToken__emptyRow} pb-5 position-relative`}>
            <Col lg={{ span: 8, offset: 2 }} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
              <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
                <h2 className={`${Classes.launchpad__title} text-uppercase aboro_font`}>My Lock Info</h2>

                <div className={`${Classes.mylock_countdownHolder} mb-5 mt-4`}>
                  <p className={`${Classes.countdownTitle} text-uppercase m-0 mb-2 roboto text-center`}>unlock in</p>
                  <Countdown date={new Date(parseInt(lockInfo?.unlockOn) * 1000)} renderer={renderer} />
                </div>

                <div className={`${Classes.flexHolder}`}>
                  <h2 className={`${Classes.launchpad__subTitle} text-uppercase aboro_font`}>Token Info</h2>
                  <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                    <p className={`${Classes.flexLabel} m-0 roboto`}>Token Address</p>
                    <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo?.splMint?.toString()}</p>
                  </div>
                  <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                    <p className={`${Classes.flexLabel} m-0 roboto`}>Token Name</p>
                    <p className={`${Classes.flexValue} roboto m-0`}>{tokeninfo ? tokeninfo?.name : ""}</p>
                  </div>
                  <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                    <p className={`${Classes.flexLabel} m-0 roboto`}>Token Symbol</p>
                    <p className={`${Classes.flexValue} roboto m-0 text-uppercase`}>{tokeninfo ? tokeninfo?.symbol : ""}</p>
                  </div>
                  <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                    <p className={`${Classes.flexLabel} m-0 roboto`}>Token Decimals</p>
                    <p className={`${Classes.flexValue} roboto m-0`}>{tokeninfo ? tokeninfo?.decimal : ""}</p>
                  </div>

                  <h2 className={`${Classes.launchpad__subTitle} text-uppercase aboro_font mt-5`}>Lock Info</h2>
                  <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                    <p className={`${Classes.flexLabel} m-0 roboto`}>Total Amount Locked</p>
                    <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo?.amountLocked / LAMPORTS_PER_SOL}</p>
                  </div>
                  <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                    <p className={`${Classes.flexLabel} m-0 roboto`}>Created User</p>
                    <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo?.authority?.toString()}</p>
                  </div>
                  <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                    <p className={`${Classes.flexLabel} m-0 roboto`}>Owner</p>
                    <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo?.reciever?.toString()}</p>
                  </div>

                  <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                    <p className={`${Classes.flexLabel} m-0 roboto`}>Lock Date</p>
                    <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo && new Date(parseInt(lockInfo?.unlockOn) * 1000).toLocaleDateString() + "  "
                      + new Date(parseInt(lockInfo?.unlockOn) * 1000).getHours() + ":" + new Date(parseInt(lockInfo?.unlockOn) * 1000).getMinutes() + ":"
                      + new Date(parseInt(lockInfo?.unlockOn) * 1000).getSeconds()}</p>
                  </div>
                </div>

                <div className={`mt-5 ${Classes.dualBtnHolder} d-flex justify-content-center align-items-center gap-3 mb-3`}>
                  <button className={`${Classes.violetBtn} px-2 px-sm-3`} disabled = {new Date().getTime() > new Date(parseInt(lockInfo?.unlockOn) * 1000 )}
                   onClick={() => { handleShowTransfer() }}>
                    <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>Transfer Lock Ownership</p></button>

                  {console.log("myLock_walet",lockInfo?.reciever,walletdetail?.walletaddress,lockInfo?.reciever?.toString() !== walletdetail?.walletaddress)}

                  <button className={`${Classes.violetBtn} px-2 px-sm-3`} 
                  disabled ={new Date().getTime() < new Date(parseInt(lockInfo?.unlockOn) * 1000) || lockInfo?.balanceOf <= 0 || lockInfo?.reciever?.toString() !== walletdetail?.walletaddress}
                  onClick={() => { unLockTokens() }}>
                    <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>{islock ? 'Unlocking...' : 'Unlock'}</p></button>

                </div>
              </div>


            </Col>
          </Row>
        </section>
      </Layout>

      {/* modals */}
      <TransferOwnerShip show={showTransferOwner} handleClose={handleCloseTransfer} getLockData= {()=>{getLockData()}} lockInfo={lockInfo} lockAdd={params?.lockAddress} />
      {/* end of modals */}

    </>
  )
}

export default MyLockDetail
