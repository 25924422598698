import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import BalanceDetails from '../../components/BalanceDetails'
import { Images } from '../../data/Images'
import Classes from '../../asset/styles/TokenLock.module.css'
import { Col, Row } from 'react-bootstrap'
import { commonData } from '../../data/CommonData'
import { NavLink } from 'react-router-dom'
import LaunchingTable from '../../components/LaunchingTable'
import NoData from '../../components/NoData'
import Uselock from '../../hooks/useLock'
import Usetoken from "../../hooks/useToken";
import { isEmpty } from '../../lib/isEmpty';
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import LockCard from './LockCard'
import { useSelector } from "react-redux";
import { Searchfn } from '../../lib/searchQuery'


const TokenLock = () => {
    const lockHook = Uselock()
    const tokenHook = Usetoken()

    const [tab, setTab] = useState('All')
    // const [tableData,setTableData] = useState(commonData?.tokenLockTable)
    const [sliceValue, setSliceValue] = useState(3)
    const [lockInfo, setLockInfo] = useState({})
    const [lockAdd, setLockAdd] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [loadData, setLoadData] = useState([])
    const [myLoadData, setMyLoadData] = useState([])
    const [loadInterval, setLoadInterval] = useState(3)
    const [interval, setInterval] = useState(1)
    const [myLockInfo, setMyLockInfo] = useState({})


    const walletdetail = useSelector((state) => state.wallet)
    console.log("walletdetail_launchLIst", walletdetail, walletdetail?.walletaddress)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getAllLockData = async () => {
        let datas = await lockHook?.UseTotalLockInfo()
        console.log("getLock_LockDetail", datas)
        setLockInfo(datas)

        let getLock = [...datas]
        let limit = [...getLock].splice(0, loadInterval)
        console.log("getLock", getLock, limit)
        setLoadData(limit)



        let myLockfilter = datas?.filter((val) => {
            console.log("myLockfilter", val)
            return val?.account?.authority?.toString() === walletdetail?.walletaddress || val?.account?.reciever?.toString() === walletdetail?.walletaddress
        })
        console.log("myLockfilter", myLockfilter)
        setMyLockInfo(myLockfilter)

        let getmyLock = [...myLockfilter]
        let mylimit = [...getmyLock].splice(0, loadInterval)
        console.log("getLock", getmyLock, mylimit)
        setMyLoadData(mylimit)

    }

    useEffect(() => {
        getAllLockData()
    }, [walletdetail?.walletaddress])

    const getMyLockData = async () => {
        let datas = await lockHook?.UseTotalLockInfo()
        console.log("getLock_LockDetail", datas)

        const myLockfilter = datas?.filter((val) => {
            return val?.authority?.toString() === walletdetail?.walletaddress || val?.reciever?.toString() === walletdetail?.walletaddress
        })

        let getLock = [...myLockfilter]
        let limit = [...getLock].splice(0, loadInterval)
        console.log("getLock", getLock, limit)
        setMyLoadData(limit)

    }

    let index = 0

    const loadMore = async (interval) => {
        console.log("Load_moreee", lockInfo, interval)
        setIsLoading(true);
        setInterval(interval)
        let skip = (interval - 1) * loadInterval
        const newly = [...lockInfo];
        const limit = newly.splice(skip, loadInterval)
        console.log("newly ", newly, limit);
        setIsLoading(false);
        setLoadData([...loadData, ...limit])
    }

    const loadMyLock = async (interval) => {
        console.log("loadMyContribution", myLockInfo, interval)
        setIsLoading(true);
        setInterval(interval)
        let skip = (interval - 1) * loadInterval
        const newly = [...myLockInfo];
        // const normalSale = newly.filter((val) => {
        //   return val?.account?.isWhitelist == false
        // })
        const limit = newly.splice(skip, loadInterval)
        console.log("newly ", newly, limit);
        setIsLoading(false);
        setMyLoadData([...myLoadData, ...limit])
    }


    //   const loadMyLock = async (interval) => {
    //     console.log("loadMyContribution", myContribution, interval)
    //     setIsLoading(true);
    //     setInterval(interval)
    //     let skip = (interval - 1) * loadInterval
    //     const newly = [...myContribution];
    //     const normalSale = newly.filter((val) => {
    //       return val?.account?.isWhitelist == false
    //     })
    //     const limit = normalSale.splice(skip, loadInterval)
    //     console.log("newly ", newly, limit);
    //     setIsLoading(false);
    //     setMyLoadData([...myLoadData, ...limit])
    //   }




    return (
        <>
            <Layout>
                <section className="custom_section position-relative">
                    <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
                    <BalanceDetails />

                    <Row className={`mx-auto ${Classes.createToken__emptyRow} pb-5 position-relative`}>
                        <Col lg={{ span: 8, offset: 2 }} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
                            <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
                                <h2
                                    className={`${Classes.launchpad__title} text-uppercase aboro_font`}
                                >
                                    Token Lock
                                </h2>
                                <Row className='mt-5'>
                                    <Col md={6} sm={4} className='mb-4 mb-sm-0'>
                                        <div className={`${Classes.tabHolder} d-flex justify-content-start align-items-center gap-4`}>
                                            <button className={`${tab === 'All' ? Classes.tabBtnActive : Classes.tabBtn} poppins border-0 outline-0`} onClick={() => setTab('All')}>All</button>
                                            <button className={`${tab === 'My Lock' ? Classes.tabBtnActive : Classes.tabBtn} poppins border-0 outline-0 `} onClick={() => setTab('My Lock')}>My Lock</button>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={8}>
                                        <div className={`d-flex justify-content-end align-items-center w-100`}>
                                            <div className={`${Classes.searchBtn}`}>
                                                <p className={`${Classes.searchHint} d-none d-md-flex poppins`}>Search Token</p>
                                                <p className={`${Classes.searchHint} d-md-none`}><i class="fa-solid fa-magnifying-glass" /></p>

                                            </div>
                                            <input
                                                type="text"
                                                className={`${Classes.searchBar} flex-grow-1 poppins`}
                                                onChange={(e) => {
                                                    let result = Searchfn(lockInfo, e.target.value, ['name', 'symbol'])
                                                    console.log(result, 'result')
                                                    setLoadData(result)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {tab === 'All' &&
                                    <>
                                        {loadData && loadData?.length <= 0 ?
                                            <NoData />
                                            :

                                            <>
                                                {loadData?.length > 0 && loadData?.map((data) => {
                                                    let lockData = data?.account
                                                    let lockAdd = data?.publicKey
                                                    return (
                                                        <>
                                                            {console.log("lockInfo", data, lockData)}

                                                            <LockCard lockData={lockData} lockAdd={lockAdd} tab={tab} />
                                                            {/* <div className={`${Classes.cmnTableHolder} pb-3`}>
                                                            <table className={`${Classes.tokenCmnTable} mt-5`}>
                                                                <thead>
                                                                    <tr>
                                                                        <th className='pb-3'>Token</th>
                                                                        <th className='pb-3'>Amount</th>
                                                                        <th className='pb-3'></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className={`${Classes.gradientBorder}`}>
                                                                        <td className='py-2'>
                                                                            <div className={`${Classes.tokenDetails} d-flex justify-content-start align-items-center gap-2`}>
                                                                                <img src={Images.solana} className={`${Classes.tableTokenImg} img-fluid`} alt='' />
                                                                                <div className={``}>
                                                                                    <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Cat</p>
                                                                                    <p className={`${Classes.tableSubHint} poppins m-0 position-relative`}>links</p>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>{lockData?.amountLocked / LAMPORTS_PER_SOL} SOL</p>
                                                                        </td>
                                                                        <td className={`text-end`}>
                                                                            <NavLink to='/lock-detail' className={`poppins cmnNavLink position-relative px-3 ${Classes.tableNavLink}`}>View</NavLink>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div> */}
                                                        </>
                                                    )

                                                })
                                                }


                                                {lockInfo?.length > loadData?.length ?
                                                    <div className={`text-center w-100 mt-5`}>
                                                        <button className={`${Classes.violetBtn} px-2 px-sm-3`} onClick={() => { loadMore(interval + 1) }}>
                                                            <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>
                                                                {isLoading ? 'View More Locks...' : 'View More Locks'}
                                                            </p>
                                                        </button>
                                                    </div> : <div></div>}
                                            </>
                                        }
                                    </>
                                }

                                {tab === 'My Lock' &&

                                    <>
                                   { console.log("myLoadData",myLoadData,myLoadData?.length <= 0,myLoadData?.length)}

                                        {myLoadData && myLoadData?.length <= 0 ?
                                            <NoData />
                                            :
                                            <>
                                                {myLoadData?.length > 0 && myLoadData?.map((data, i) => {
                                                    let lockData = data?.account
                                                    let lockAdd = data?.publicKey

                                                    console.log("condition", lockData?.authority.toString() === walletdetail?.walletaddress)
                                                    if (lockData?.authority?.toString() === walletdetail?.walletaddress || lockData?.reciever?.toString() === walletdetail?.walletaddress) {
                                                        index = index + i
                                                        return (
                                                            <>
                                                                {console.log("lockInfo_myLock", data, lockData)}



                                                                <LockCard lockData={lockData} lockAdd={lockAdd} tab={tab} />

                                                                {/* <div className={`${Classes.cmnTableHolder} pb-3`}>
                                                    <table className={`${Classes.tokenCmnTable} mt-5`}>
                                                        <thead>
                                                            <tr>
                                                                <th className='pb-3'>Token</th>
                                                                <th className='pb-3'>Amount</th>
                                                                <th className='pb-3'></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className={`${Classes.gradientBorder}`}>
                                                                <td className='py-2'>
                                                                    <div className={`${Classes.tokenDetails} d-flex justify-content-start align-items-center gap-2`}>
                                                                        <img src={Images.solana} className={`${Classes.tableTokenImg} img-fluid`} alt='' />
                                                                        <div className={``}>
                                                                            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>Cat</p>
                                                                            <p className={`${Classes.tableSubHint} poppins m-0 position-relative`}>links</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>15 SOL</p>
                                                                </td>
                                                                <td className={`text-end`}>
                                                                    <NavLink to='/mylock-detail' className={`poppins cmnNavLink position-relative px-3 ${Classes.tableNavLink}`}>View</NavLink>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div> */}
                                                            </>
                                                        )

                                                    }
                                                    else if (lockInfo?.length - 1 == i) {
                                                        if (index == 0) {
                                                            return (
                                                                <NoData />
                                                            )
                                                        }

                                                    }

                                                })
                                                }

                                                {myLockInfo?.length > myLoadData?.length ?
                                                    <div className={`text-center w-100 mt-5`}>
                                                        <button className={`${Classes.violetBtn} px-2 px-sm-3`} onClick={() => { loadMyLock(interval + 1) }}>
                                                            <p className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}>
                                                                {isLoading ? 'View More Locks...' : 'View More Locks'}
                                                            </p>
                                                        </button>
                                                    </div> : <div></div>}
                                            </>
                                        }

                                    </>

                                }


                            </div>
                        </Col>

                    </Row>


                    {/* <LaunchingTable/> */}
                </section>
            </Layout >

        </>
    )
}

export default TokenLock
