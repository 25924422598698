import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Classes from "../asset/styles/AdminKYCModal.module.css";
import { IoMdClose } from "react-icons/io";
import { Images } from "../data/Images";
import { isEmpty } from "../lib/isEmpty";
import toast from "react-hot-toast";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { API_URL } from "../config";
import { approveUserKyc, rejectUserKyc } from "../actions/adminAction";

const AdminKYCModal = ({ show, handleClose, record, walletaddress }) => {
  const [formvalue, setFormvalue] = useState(record);
  useEffect(() => {
    console.log(record, "record");
    if (!isEmpty(record)) {
      setFormvalue(record);
    }
  }, [record]);

  const onhandleClose = (e) => {
    try {
      setFormvalue();
      handleClose();
    } catch (err) {
      console.log(err, "handleChange");
    }
  };

  const fileDownloader = (URL) => {
    console.log("URL++++++++++", URL);

    try {
      let filename = "MultiFilesDownload";
      const zip = new JSZip();
      const folder = zip.folder("project");

      const blobPromise = fetch(URL).then(function (response) {
        console.log({ response });
        if (response.status === 200 || response.status === 0) {
          return Promise.resolve(response.blob());
        } else {
          return Promise.reject(new Error(response.statusText));
        }
      });
      const name = URL.substring(URL.lastIndexOf("/"));
      folder.file(name, blobPromise);
      zip
        .generateAsync({ type: "blob" })
        .then((blob) => saveAs(blob, filename), toast.success("Downloaded"))
        .catch((e) => console.log(e));
    } catch (err) {
      console.log(err, "ERR");
    }
  };

  const approve = async (id) => {
    try {
      let { status, message, result } = await approveUserKyc({
        walletaddress: walletaddress,
        id: id,
      });
      if (status == true) {
        toast.success("Kyc Approved");
        onhandleClose();
        window.location.reload()
      }
    } catch (err) {
      console.log(err);
    }
  };

  const reject = async (id) => {
    try {
      let { status, message, result } = await rejectUserKyc({
        walletaddress: walletaddress,
        id: id,
      });
      if (status == true) {
        toast.success("Kyc Rejected");
        onhandleClose();
        window.location.reload()

      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={(e) => onhandleClose(e)}
        backdrop="static"
        keyboard={false}
        className={`cmnModal`}>
        <Modal.Body>
          <div className={`${Classes.modalHeader} text-end`}>
            <button
              className={`${Classes.modalCloser}`}
              onClick={() => {
                onhandleClose();
              }}>
              <IoMdClose />
            </button>
          </div>
          <h5
            className={`${Classes.modalTitle} m-0 text-center roboto mt-3 text-uppercase`}>
            KYC
          </h5>
          <p className={`${Classes.identityType} m-0 roboto mt-4`}>Voter ID</p>

          <div className={`${Classes.singleInpHolder} mt-3`}>
            <label for="identity" className={`${Classes.inputLabel} orbitron`}>
              Identity Type
            </label>
            <br />
            <input
              type="text"
              id="identity"
              name="identity"
              value={formvalue?.identitytype}
              className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
              placeholder=""
              autoComplete="off"
            />
          </div>

          {formvalue?.identitytype === "Individual" && (
            <>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label for="email" className={`${Classes.inputLabel} orbitron`}>
                  Mail ID
                </label>
                <br />
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={formvalue?.email}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label for="city" className={`${Classes.inputLabel} orbitron`}>
                  City
                </label>
                <br />
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formvalue?.city}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="region"
                  className={`${Classes.inputLabel} orbitron`}>
                  Religion
                </label>
                <br />
                <input
                  type="text"
                  id="region"
                  name="region"
                  value={formvalue?.region}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="country"
                  className={`${Classes.inputLabel} orbitron`}>
                  Country
                </label>
                <br />
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={formvalue?.country}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="zipCode"
                  className={`${Classes.inputLabel} orbitron`}>
                  Zip Code
                </label>
                <br />
                <input
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  value={formvalue?.zipcode}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="contactnumber"
                  className={`${Classes.inputLabel} orbitron`}>
                  Contact Number
                </label>
                <br />
                <input
                  type="text"
                  id="contactnumber"
                  name="contactnumber"
                  value={formvalue?.contact}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
            </>
          )}

          {formvalue?.identitytype === "Company" && (
            <>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="businessemail"
                  className={`${Classes.inputLabel} orbitron`}>
                  Mail ID
                </label>
                <br />
                <input
                  type="text"
                  id="businessemail"
                  name="businessemail"
                  value={formvalue?.businessemail}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="businesscity"
                  className={`${Classes.inputLabel} orbitron`}>
                  City
                </label>
                <br />
                <input
                  type="text"
                  id="businesscity"
                  name="businesscity"
                  value={formvalue?.businesscity}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="businessregion"
                  className={`${Classes.inputLabel} orbitron`}>
                  Religion
                </label>
                <br />
                <input
                  type="text"
                  id="businessregion"
                  name="businessregion"
                  value={formvalue?.businessregion}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="businesscountry"
                  className={`${Classes.inputLabel} orbitron`}>
                  Country
                </label>
                <br />
                <input
                  type="text"
                  id="businesscountry"
                  name="businesscountry"
                  value={formvalue?.businesscountry}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="businesszipcode"
                  className={`${Classes.inputLabel} orbitron`}>
                  Zip Code
                </label>
                <br />
                <input
                  type="text"
                  id="businesszipcode"
                  name="businesszipcode"
                  value={formvalue?.businesszipcode}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="businesscontactnumber"
                  className={`${Classes.inputLabel} orbitron`}>
                  Contact Number
                </label>
                <br />
                <input
                  type="text"
                  id="businesscontactnumber"
                  name="businesscontactnumber"
                  value={formvalue?.businesscontactnumber}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
            </>
          )}

          {formvalue?.identitytype === "Nonprofit Organization" && (
            <>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="nonprofitemailid"
                  className={`${Classes.inputLabel} orbitron`}>
                  Mail ID
                </label>
                <br />
                <input
                  type="text"
                  id="nonprofitemailid"
                  name="nonprofitemailid"
                  value={formvalue?.nonprofitemailid}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="nonprofitcity"
                  className={`${Classes.inputLabel} orbitron`}>
                  City
                </label>
                <br />
                <input
                  type="text"
                  id="nonprofitcity"
                  name="nonprofitcity"
                  value={formvalue?.nonprofitcity}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="nonprofitregion"
                  className={`${Classes.inputLabel} orbitron`}>
                  Religion
                </label>
                <br />
                <input
                  type="text"
                  id="nonprofitregion"
                  name="nonprofitregion"
                  value={formvalue?.nonprofitregion}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="nonprofitcountry"
                  className={`${Classes.inputLabel} orbitron`}>
                  Country
                </label>
                <br />
                <input
                  type="text"
                  id="nonprofitcountry"
                  name="nonprofitcountry"
                  value={formvalue?.nonprofitcountry}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="nonprofitzipcode"
                  className={`${Classes.inputLabel} orbitron`}>
                  Zip Code
                </label>
                <br />
                <input
                  type="text"
                  id="nonprofitzipcode"
                  name="nonprofitzipcode"
                  value={formvalue?.nonprofitzipcode}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
              <div className={`${Classes.singleInpHolder} mt-4`}>
                <label
                  for="nonprofitcontactnumber"
                  className={`${Classes.inputLabel} orbitron`}>
                  Contact Number
                </label>
                <br />
                <input
                  type="text"
                  id="nonprofitcontactnumber"
                  name="nonprofitcontactnumber"
                  value={formvalue?.nonprofitcontactnumber}
                  readOnly
                  className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                  placeholder=""
                  autoComplete="off"
                />
              </div>
            </>
          )}

          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="idtype" className={`${Classes.inputLabel} orbitron`}>
              Document Type
            </label>
            <br />
            <input
              type="text"
              id="idtype"
              name="idtype"
              value={formvalue?.idtype}
              readOnly
              className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
              placeholder=""
              autoComplete="off"
            />
          </div>

          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="idnumber" className={`${Classes.inputLabel} orbitron`}>
              ID Number
            </label>
            <br />
            <input
              type="text"
              id="idnumber"
              name="idnumber"
              readOnly
              value={formvalue?.idnumber}
              className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
              placeholder=""
              autoComplete="off"
            />
          </div>

          <div className={`${Classes.singleInpHolder} mt-4`}>
            <label for="zipfile" className={`${Classes.inputLabel} orbitron`}>
              KYC Document
            </label>
            <br />
            <div className={`${Classes.documentHolder} mt-4`}>
              <img
                src={Images.document}
                name="zipfile"
                className={`${Classes.documenter}`}
                alt=""
              />
            </div>

            <button
              className={`${Classes.whiteBtn} mt-3`}
              onClick={() => {
                fileDownloader(
                  API_URL + "/" + formvalue?.path + "/" + formvalue?.zipfile
                );
              }}>
              Download
            </button>
          </div>

          {formvalue?.status == "Pending" ? (
            <>
              <div
                className={`d-flex justify-content-center align-items-center gap-3 w-100 mt-5`}>
                <button
                  className={`${Classes.whiteBtn} poppins`}
                  onClick={() => approve(formvalue.id)}>
                  Approve
                </button>
                <button
                  className={`${Classes.whiteBorderBtn} poppins`}
                  onClick={() => reject(formvalue.id)}>
                  Reject
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                className={`d-flex justify-content-center align-items-center gap-3 w-100 mt-5`}>
                <button
                  className={`${Classes.whiteBtn} poppins`}
                  disabled={true}>
                  Approve
                </button>
                <button
                  className={`${Classes.whiteBtn} poppins`}
                  disabled={true}>
                  Reject
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminKYCModal;
