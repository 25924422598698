import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Images } from '../../data/Images'
import Classes from '../../asset/styles/CreateLock.module.css'
import BalanceDetails from '../../components/BalanceDetails'
import { Col, Row } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LaunchingTable from '../../components/LaunchingTable'
import Config from "../../config"
import { isEmpty } from '../../lib/isEmpty'
import Usetoken from "../../hooks/useToken";
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import Uselock from '../../hooks/useLock'
import { useLocation, useNavigate } from 'react-router-dom'
import { validtokenlock } from '../../validations/userValidations'

const CreateLock = () => {
  const location = useLocation()
  const tokenHook = Usetoken()
  const lockHook = Uselock()


  const [anotherOwner, setAnotherOwner] = useState(false)
  const [vesting, setVesting] = useState(false)
  const [customizeLock, setCustomizeLock] = useState(false)
  const [customDate, setCustomDate] = useState(new Date())
  const [date, setDate] = useState()
  const [locktime, setLocktime] = useState('')
  const [address, setAddress] = useState('')
  const [tokeninfo, setTokeninfo] = useState({})
  const [anotherowneraddress, setAnotherowneraddress] = useState('')
  const [amount, setAmount] = useState('')
  const [errors, setErrors] = useState({})
  const [cycle, setCycle] = useState(0)
  const [cyclepercent, setCyclepercent] = useState(0)
  const [datestatus, setDatestatus] = useState(false)
  const [islock, setIslock] = useState(false)
  const [showlocktime, setShowLocktime] = useState('')
  const [lockAdd, setLockAdd] = useState('')

  const walletdetail = useSelector((state) => state.wallet)
  console.log("walletdetail_TokenLock", walletdetail, walletdetail?.walletData?.walletBal)

  const navigate = useNavigate()


  const handleAddMonths = (e) => {
    const newDate = new Date().setMonth(new Date().getMonth() + e)
    setDate(new Date(newDate))
  }
  console.log("agjduadwd", date);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  const threeMonths = () => {
    const currentDate = Date.now();
    console.log("currentDate", currentDate * 1000)
    let Month = Config?.ThreeMonthsInSeconds
    console.log("currentDate1", new Date(Month), new Date(Month), Config?.ThreeMonthsInSeconds, Config?.ThreeMonthsInSeconds * 1000)
    let showMonth = new Date(((new Date().getTime() / 1000) + Month) * 1000).getTime()
    setShowLocktime(showMonth)
    setLocktime(Month)
  }

  const sixMonths = () => {
    const currentDate = Date.now();
    console.log("currentDate", currentDate * 1000)
    let Month = Config?.SixMonthsInSeconds
    console.log("currentDate1", new Date(Month), Config?.SixMonthsInSeconds, Config?.SixMonthsInSeconds * 1000, ((new Date().getTime() / 1000) + Month))
    let showMonth = new Date(((new Date().getTime() / 1000) + Month) * 1000).getTime()
    setShowLocktime(showMonth)
    setLocktime(Month)
  }

  const twelveMonths = () => {
    const currentDate = Date.now();
    console.log("currentDate", currentDate * 1000)
    let Month = Config?.TwelveMonthsInSeconds
    console.log("currentDate1", new Date(Month), Config?.TwelveMonthsInSeconds, Config?.TwelveMonthsInSeconds * 1000)
    let showMonth = new Date(((new Date().getTime() / 1000) + Month) * 1000).getTime()
    setShowLocktime(showMonth)
    setLocktime(Month)
  }

  useEffect(() => {
    getTokenInfo()
  }, [address])

  const getTokenInfo = async () => {
    if (!isEmpty(address)) {
      // let tokenInfo = await tokenHook.getTokenbalance(walletdetail?.walletaddress, address)
      let tokenInfo = await tokenHook.getTokenNameSymbol(address)
      console.log("tokenInfo", tokenInfo)
      setTokeninfo(tokenInfo)
    }
  }

  const createLockTokens = async () => {

    let payload = {};
    payload.address = address;
    payload.amount = amount;
   
    if (anotherOwner) {
      payload.anotherOwner = anotherOwner
      payload.anotherowneraddress = anotherowneraddress;
    }
    if (vesting) {
      payload.cycle = cycle;
      payload.cyclepercent = cyclepercent;
      payload.locktime = locktime;
      payload.vesting = true
    }
    else {
      payload.locktime = locktime;
    }
    console.log('payload', payload);
    const validate = validtokenlock(payload);
    if (validate.isValid) {

      setIslock(true)
      let lockTime = (new Date(showlocktime).getTime() - new Date().getTime()) / 1000
      lockTime = lockTime.toFixed(0)
      console.log(lockTime, 'lockTime', new Date(showlocktime).getTime(), new Date().getTime())
      let cycleDays = parseFloat(cycle) * 86400
      let lockInfo = {
        lockamount: amount * LAMPORTS_PER_SOL,
        lockDuration: lockTime,
        tokenAddress: address,
        receiverAddress: !isEmpty(anotherowneraddress) ? anotherowneraddress : walletdetail?.walletaddress,
        isVested: vesting,
        cycle: cycleDays,
        cyclepercent: cyclepercent
      }
      console.log("createLockTokens", lockInfo)
      var result = await lockHook.UseLockToken(lockInfo)
      console.log("result", result)
      setIslock(false)
      if (result?.status == true) {
        toast.success('Tokens locked Successfully..!')
        navigate(`/mylock-detail/${result?.lock}`)
        setLockAdd(result?.lock)
      }
      else {
        toast.error('Error while locking token..!')
      }

    }
    else {
      setErrors(validate.errors)
    }
  }

  return (
    <>
      <Layout>
        <section className="custom_section position-relative">
          <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
          <BalanceDetails />

          <Row className={`mx-auto ${Classes.createToken__emptyRow} pb-5`}>
            <Col lg={{ span: 8, offset: 2 }} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
              <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
                <h2
                  className={`${Classes.launchpad__title} text-uppercase aboro_font`}>
                  Create Token Lock
                </h2>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="tokenAddress" className={`${Classes.inputLabel} orbitron`}>
                    {location.pathname == '/lp-token-lock' ? 'Token LP Address' : 'Token Address'} *</label><br />
                  <input type="text" id="tokenAddress" name="tokenAddress"
                    value={address} onChange={(e) => {
                        setAddress(e.target.value)
                        if (isEmpty(e.target.value)) {
                          const formvalue = { ...errors, ["address"]: "Invalid Token address" }
                          setErrors(formvalue)
                        }
                        else if (!isEmpty(e.target.value)) {
                          const formvalue = { ...errors, ["address"]: "" }
                          setErrors(formvalue)
                        }
                                             
                      }}

                    className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                    placeholder='Ex.3ToGU6gtJJZ2NGTgps8h23akNn5asR9jYv1qwuVRgR2b' autoComplete="off" />
                </div>
                <span className={`cmnErrorHint`}>{errors?.address && errors?.address}</span>


                {!isEmpty(tokeninfo) ?
                  <div className={`${Classes.tokenDetails} mt-4`}>
                    <div className={`d-flex justify-content-between align-items-center mb-2`}>
                      <p className={`${Classes.radioHint} m-0`}>Name :</p>
                      <p className={`${Classes.radioHint} m-0`}>{tokeninfo?.name}</p>
                    </div>
                    <div className={`d-flex justify-content-between align-items-center mb-2`}>
                      <p className={`${Classes.radioHint} m-0`}>Symbol :</p>
                      <p className={`${Classes.radioHint} m-0`}>{tokeninfo?.symbol}</p>
                    </div>
                    {!isEmpty(tokeninfo?.decimal) ?
                      <div className={`d-flex justify-content-between align-items-center mb-2`}>
                        <p className={`${Classes.radioHint} m-0`}>Decimals :</p>
                        <p className={`${Classes.radioHint} m-0`}>{tokeninfo?.decimal}</p>
                      </div>
                      : ""}
                  </div>
                  : ""}

                <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-2 mt-3 pointer`}
                  onClick={(e) => { setAnotherOwner(!anotherOwner) }}>
                  <i className={`fa-solid fa-square ${anotherOwner ? Classes.radioCheckerActive : Classes.radioChecker}`} />
                  <p className={`${Classes.radioHint}`}>Use another owner?</p>
                </button>
                {console.log("anotherOwner",anotherOwner,anotherowneraddress)}
                {/* another owner */}
                {anotherOwner &&
                  <>
                    <div className={`${Classes.singleInpHolder} mt-4`}>
                      <label for="owner" className={`${Classes.inputLabel} orbitron`}>Owner</label><br />
                      <input type="text" id="owner" name="anotherowneraddress"
                        onChange={(e) => { 
                          setAnotherowneraddress(e.target.value)
                          if (isEmpty(e.target.value)) {
                            const formvalue = { ...errors, ["anotherowneraddress"]: "Invalid Owner address" }
                            setErrors(formvalue)
                          }
                          else if (!isEmpty(e.target.value)) {
                            const formvalue = { ...errors, ["anotherowneraddress"]: "" }
                            setErrors(formvalue)
                          }
                         }}
                        className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                        placeholder='Ex.3ToGU6gtJJZ2NGTgps8h23akNn5asR9jYv1qwuVRgR2b' autoComplete="off" />
                    </div>
                    <span className={`cmnErrorHint`}>{errors?.anotherowneraddress && errors?.anotherowneraddress}</span>
                  </>
                }

                {/* end of another owner */}

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="amount" className={`${Classes.inputLabel} orbitron`}>Amount</label><br />
                  <div className={`${Classes.inputHolder} mt-3 d-flex justify-content-between align-items-center`}>
                    <input type="number" id="amount" name="amount"
                      value={amount}
                      onChange={(e) => {
                        var numbers = /^\d+((.)|(.\d{0,4})?)$/;
                        if (!numbers.test(e.target.value) && e.target.value !== "") {
                          return false
                        }
                        if (isEmpty(e.target.value) || e.target.value == 0) {
                          const formvalue = { ...errors, ["amount"]: "Invalid amount" }
                          setErrors(formvalue)
                        }
                        else if (!isEmpty(e.target.value)) {
                          const formvalue = { ...errors, ["amount"]: "" }
                          setErrors(formvalue)
                        }
                        setAmount(e?.target?.value)
                      }}
                      className={`${Classes.inputOnly} inter_font flex-grow-1`}
                      placeholder='Ex.Enter Amount' autoComplete="off" />
                    <button className={`${Classes.customGradBtn} border-0 outline-0 `}
                      disabled={errors?.address || !address}
                      onClick={async () => {
                        const balance = tokeninfo?.tokenBalance;
                        setAmount(balance)

                      }}
                    >Max</button>
                  </div>
                  <span className={`cmnErrorHint`}>{errors?.amount && errors?.amount}</span>

                  <button className={`${Classes.singleRadio} d-flex justify-content-start align-items-center gap-2 mt-3 pointer`}
                    onClick={() => setVesting(!vesting)}>
                    <i className={`fa-solid fa-square ${vesting ? Classes.radioCheckerActive : Classes.radioChecker}`} />
                    <p className={`${Classes.radioHint}`}>Use Vesting?</p>
                  </button>
                </div>

                <div className={`${Classes.singleInpHolder} mt-4`}>
                  <label for="lockUntil" className={`${Classes.inputLabel} orbitron`}>
                    {vesting || customizeLock ? `Lock Until` : `Lock Until`} *</label><br />
                  <div className={`${Classes.durationHolder} d-flex justify-content-between align-items-center mt-3 position-relative`}>
                    {customizeLock ?
                      <>
                        <div className={`${Classes.datePickerHolder} w-100`}>
                          <DatePicker
                            name="startTime"
                            minDate={new Date(Date.now() + (600000))}
                            showTimeSelect
                            selected={isEmpty(showlocktime) ? '' : new Date(showlocktime)}
                            onChange={(date) => {
                              console.log("locktime", date, new Date(date).getTime(), new Date().getTime(), (new Date(date).getTime() - new Date().getTime()) / 1000);
                              let formData = { ...errors, ["locktime"]: "" };
                              let differSec = (new Date(date).getTime() - new Date().getTime()) / 1000
                              setErrors(formData);
                              setLocktime(differSec.toFixed(0));
                              setShowLocktime(new Date(date).getTime())
                              setDatestatus(true)
                              if (Date.parse(date) > Date.now() + (600000)) {
                                let formData = { ...errors, ["locktime"]: "" };
                                setErrors(formData);
                              }
                              else {
                                let formData = { ...errors, ["locktime"]: "Unlock time needs to be after 10 minutes from now" };
                                setErrors(formData);
                                setLocktime("")
                              }
                            }}
                            // onKeyDown={e => e.preventDefault()}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className={`roboto flex-grow-1`} />
                        </div>
                        <button className={`${Classes.gradBtnAbs} border-0 outline-0 `}><i class="fa-solid fa-calendar-days" /></button>

                      </>
                      :
                      <>
                        {console.log("locktime", showlocktime, locktime)}
                        <p className={`${Classes.radioHint} flex-grow-1`}>{showlocktime ? new Date(showlocktime).toLocaleDateString() : ""}</p>
                        <div className={`${Classes.durationSelector} d-flex gap-2`}>
                          <button className={`${Classes.customGradBtn} border-0 outline-0`} onClick={() => { threeMonths() }}>3M</button>
                          <button className={`${Classes.customGradBtn} border-0 outline-0`} onClick={() => { sixMonths() }}>6M</button>
                          <button className={`${Classes.customGradBtn} border-0 outline-0`} onClick={() => { twelveMonths() }}>12M</button>
                        </div>
                      </>
                    }

                  </div>
                  <span className={`cmnErrorHint`}>{errors?.locktime && errors?.locktime}</span>
                </div>

                <button className={`${Classes.outlineBtn} mt-3`}
                  onClick={() => setCustomizeLock(!customizeLock)}>
                  {customizeLock ? `Remove Customize Lock` : `Customize Lock`}</button>

                {/* vesting content */}
                {vesting &&
                  <>
                    <div className={`${Classes.singleInpHolder} mt-4`}>
                      <label for="cycleDays" className={`${Classes.inputLabel} orbitron`}>Cycle (days)</label><br />
                      <input type="text" id="cycleDays" name="cycleDays"
                        onChange={(e) => {
                          var numbers = /^\d+((.)|(.\d{0,4})?)$/;
                          if (!numbers.test(e.target.value) && e.target.value !== "") {
                            return false
                          }
                          if (isEmpty(e.target.value)) {
                            const formvalue = { ...errors, ["cycle"]: "Invalid cycle days" }
                            setErrors(formvalue)
                          }
                          else if (!isEmpty(e.target.value)) {
                            const formvalue = { ...errors, ["cycle"]: "" }
                            setErrors(formvalue)
                          }
                          setCycle(e.target.value)
                        }}
                        value={cycle}

                        className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                        placeholder='Ex.John' autoComplete="off" />
                    </div>

                    <span className={`cmnErrorHint`}>{errors?.cycle && errors?.cycle}</span>


                    <div className={`${Classes.singleInpHolder} mt-4`}>
                      <label for="cycleReleasePercent" className={`${Classes.inputLabel} orbitron`}>Cycle Release Percent</label><br />
                      <input type="text" id="cycleReleasePercent" name="cycleReleasePercent"
                        onChange={(e) => {
                          var numbers = /^\d+((.)|(.\d{0,4})?)$/;
                          if (!numbers.test(e.target.value) && e.target.value !== "") {
                            return false
                          }
                          if (isEmpty(e.target.value)) {
                            const formvalue = { ...errors, ["cyclepercent"]: "Invalid cycle percent" }
                            setErrors(formvalue)
                          }
                          else if (!isEmpty(e.target.value)) {
                            const formvalue = { ...errors, ["cyclepercent"]: "" }
                            setErrors(formvalue)
                          }
                          setCyclepercent(e.target.value)
                        }
                        }
                        value={cyclepercent}
                        className={`${Classes.inputBox} inter_font mt-3 w-100 p-2`}
                        placeholder='Ex.John' autoComplete="off" />
                    </div>

                    <span className={`cmnErrorHint`}>{errors?.cyclepercent && errors?.cyclepercent}</span>


                  </>
                }

                {/* end of vesting content */}

                <button onClick={() => { createLockTokens() }} disabled ={walletdetail?.walletData?.walletBal < 0.01}
                  className={`${Classes.gradientBtn} border-0 outline-0 w-100 py-3 mt-5 d-flex flex-column align-items-center`}>
                  <h5 className={`${Classes.stepTitle} orbitron m-0`}>{islock ? 'Creating Lock...' : 'Create Lock'}</h5>
                  {/* <p className={`${Classes.stepsDesc} poppins m-0 mt-2`}>Please exclude SPAILock's lockup address 0x2e8bAe13157A414AcA1a50E24a08c2357B2d65C0 from fees, rewards, max tx amount to start locking tokens. We don't support rebase tokens. */}
                  {/* </p> */}
                </button>
              </div>
            </Col>
          </Row>

          <div className={`mt-5`}>
            <LaunchingTable />
          </div>
        </section>
      </Layout>

    </>
  )
}

export default CreateLock
