import React, { useEffect, useState } from 'react'
import Classes from "../asset/styles/Launchpad.module.css";
import { useLocation } from 'react-router-dom';
import ConnectWalletModal from '../Modals/ConnectWalletModal';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../lib/isEmpty';
// import { getWalletAddress } from '../hooks/useStorage';
import Walletbutton from './walletbutton';
import HookFunction from "../hooks/useStorage"
import Usesale from '../hooks/useSale';
import Uselock from '../hooks/useLock';


const BalanceDetails = () => {

  const saleHook = Usesale()
  const lockHook = Uselock()

  // modal states
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [wallet, setWallet] = useState(false)
  const [saleCount,setSaleCount] = useState(0)
  const [lockCount,setLockCount] = useState(0)

  const location = useLocation()
  const storageHooks = HookFunction()



  const walletdetail = useSelector((state) => state.wallet)
  console.log("walletdetail_ballance", walletdetail)

  useEffect(()=>{
    storageHooks.getWalletAddress()
     
  },[walletdetail])



  const getSaleData = async() =>{
    let TotalData = await saleHook?.UseTotalSaleInfo()
    console.log("getSale_Count", TotalData,TotalData?.length)
    setSaleCount(TotalData?.length)
  }

  useEffect(()=>{
    getSaleData()
    getLockData()
 },[])


 const getLockData = async() =>{
  let datas = await lockHook?.UseTotalLockInfo()
  console.log("getLock_Count", datas, datas?.length)
  setLockCount(datas?.length)
}



  return (
    <>
      <Row className={`h-100 mx-auto`}>
        <Col sm={6} lg={{ span: 4, offset: 2 }} className={`${Classes.col_min_height}`}>
          <div className={`${Classes.borderShower} w-100 py-5 h-100`}>
            <div
              className={`${Classes.topDualBox} d-flex justify-content-center align-items-center`}
            >
              <p
                className={`${Classes.dualBox_hint} ${Classes.topDualBoxHint} roboto text-uppercase w-75 m-0 mt-5`}
              >
                {location.pathname == '/kyc' ? `kyc` : location.pathname == '/create-launchpad' ? `create your launchpad here` : location.pathname == '/token-creation' ? `create your token here` : location.pathname == '/launchpad-list' ? `the below section is for premium gems` : location.pathname == '/token-lock' ? `lock your token here` : location.pathname == '/edit-launchpad' || location.pathname == '/edit-private-sale' ? `The Below Section is For Edit Sale Details` : `The Below Section is For Premium Gems`}
              </p>
            </div>

            <h1
              className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font mt-3 ps-3`}>
              {location.pathname == '/kyc' ? `kyc` : location.pathname == '/token-lock' || location.pathname == '/create-lock' || location.pathname == '/lp-token-lock' || location.pathname == '/mylock-detail' ? `Locking` : `Launching`}

            </h1>
          </div>
        </Col>
        <Col sm={6} lg={4} className={`${Classes.col_min_height}`}>
          <div className={`${Classes.borderShowerRight} w-100 py-5 h-100`}>
            <p className={`${Classes.yourBalance} text-uppercase roboto m-0 ps-4 position-relative`}>
              Your Balance
            </p>

            <ul className={`${Classes.salesCard__unorderList} mt-3 position-relative`}>
              <li className={`${Classes.gray__lister} py-2 px-3`}>
                <p className={`${Classes.listTextWhite} ${Classes.listBeforeAlign} roboto`}>
                  SOL Available :{!isEmpty(walletdetail?.walletData?.walletBal) ? walletdetail?.walletData?.walletBal : 0}
                </p>
              </li>
              <li className={`${Classes.gray__lister} py-2 px-3`}>
                <div
                  className={`d-flex justify-content-between align-items-center`}
                >
                  <p
                    className={`${Classes.listTextWhite} ${Classes.listBeforeAlign} roboto`}
                  >
                    Sale Participation : {!isEmpty(saleCount) ? saleCount : 0}
                  </p>

                </div>
              </li>
              <li className={`${Classes.gray__lister} py-2 px-3`}>
                <div
                  className={`d-flex justify-content-between align-items-center`}
                >
                  <p
                    className={`${Classes.listTextWhite} ${Classes.listBeforeAlign} roboto`}
                  >
                    Token Lock : {!isEmpty(lockCount) ? lockCount : 0}
                  </p>
                </div>
              </li>
            </ul>

            {/* <div className={`text-end position-relative`}>
              {isEmpty(walletdetail.walletaddress) ?
                <button
                  className={`${Classes.launchpad__blueBtn} text-uppercase mt-3 mb-5 roboto`}
                  onClick={handleShow}
                >
                  <i class="fa-solid fa-circle me-1" />
                  <i class="fa-solid fa-circle me-3" />
                  Connect Wallet
                </button>
                : <button
                  className={`${Classes.launchpad__blueBtn} text-uppercase mt-3 mb-5 roboto`} disabled
                // onClick={handleShow} 
                >
                  <i class="fa-solid fa-circle me-1" />
                  <i class="fa-solid fa-circle me-3" />
                  {`${(walletdetail?.walletaddress).substring(0, 5)}...${walletdetail?.walletaddress.substring(38, 42)}`}
                </button>
              }
            </div> */}
<div className={`text-end position-relative`}>
           <Walletbutton/>
           </div>

          </div>
        </Col>
        <div className={`col-lg-2 d-none d-lg-flex  ${Classes.col_min_height}`}></div>
      </Row>

      {/* modals */}
      <ConnectWalletModal show={show} handleClose={handleClose} wallet={wallet} setWallet={setWallet} />
      {/* end of modals */}
    </>
  )
}

export default BalanceDetails
