import React, { useEffect, useState } from 'react'
import Classes from '../asset/styles/PrivateSalesCard.module.css'
import { Images } from '../data/Images'
// import Countdown from '../components/Countdown';
import Countdown from 'react-countdown';
import { Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Usesale from '../hooks/useSale';
import Config from "../config"
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import Usetoken from '../hooks/useToken';
import { isEmpty } from '../lib/isEmpty';
// import { UsecreateTokenAndChangeAuthorities } from '../hooks/useSolana';
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { createMint, getOrCreateAssociatedTokenAccount, mintTo, setAuthority, AuthorityType } from "@solana/spl-token";
import { Keypair, PublicKey } from "@solana/web3.js";
import { duration } from '../lib/dateTimeHelper';

const PrivateSalesCard = ({ saleData, saleDBData }) => {
  const saleHook = Usesale()
  const tokenHook = Usetoken()
  const [tokeninfo, setTokeninfo] = useState({})
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [mintAddress, setMintAddress] = useState(null);


  console.log("saleData>>>>>>>>1", useWallet())
  const saleInfo = saleData?.account
  var percentage = saleInfo && parseInt(parseInt(saleInfo?.currencyRaised) / parseInt(saleInfo?.softCap) * 100)


  useEffect(() => {
    getTokenInfo()
  }, [saleInfo?.splMint])



  const getTokenInfo = async () => {
    console.log("tokeninfoooo", saleInfo?.splMint)
    if (!isEmpty(saleInfo?.splMint)) {
      // let tokenInfo = await tokenHook.getTokenbalance(walletdetail?.walletaddress,  saleInfo?.splMint)
      let tokenInfo = await tokenHook.getTokenNameSymbol(saleInfo?.splMint)
      console.log("tokenInfo", tokenInfo)
      setTokeninfo(tokenInfo)
    }
  }

  const renderer = ({ days, hours, minutes, seconds }) => {

    return (
      <div className={`${Classes.countdownRenderer} d-flex justify-content-center align-items-center`}>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 dayset text-center position-relative roboto`}>{
          days < 10 ? `0${days}` : days}
          <span className='text-center'>Days</span>
        </p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 dayset text-center position-relative roboto`}>{hours < 10 ? `0${hours}` : hours}
          <span className='text-center'>Hrs</span>
        </p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 dayset text-center position-relative roboto`}>{minutes < 10 ? `0${minutes}` : minutes}
          <span className='text-center'>Mins</span>
        </p>
        <p className={`${Classes.card__counter} m-0 px-2 p-lg-2 p-xl-3 px-sm-3 mx-1 mx-sm-2 mx-lg-1 mx-xl-2 dayset text-center position-relative roboto`}>{seconds < 10 ? `0${seconds}` : seconds}
          <span className='text-center'>Secs</span>
        </p>
      </div>
    )
  }



  const UsecreateTokenAndChangeAuthorities = async () => {

    console.log("publicKey>>>>>>", publicKey.toBuffer());

    if (!publicKey) {
      alert("Please connect your wallet!");
      return;
    }

    // 1. Create a new mint (token)
    try {

      const mint = await createMint(
        connection,
        publicKey,  // Initial mint authority
        publicKey,  // Initial freeze authority
        9  // Number of decimals
      );

      console.log("mint", mint);
    } catch (error) {
      console.log("mintError", error);

    }


    // 2. Create an associated token account for the wallet
    // const tokenAccount = await getOrCreateAssociatedTokenAccount(
    //   connection,
    //   publicKey,
    //   mint,
    //   publicKey
    // );

    // 3.Mint some tokens to the wallet's token account
    //  const minto =  await mintTo(
    //     connection,
    //     publicKey,
    //     mint,
    //     tokenAccount.address,
    //     publicKey,  // Authority to mint tokens
    //     1000000  // Amount to mint
    //   );

    // 4.Change the mint authority (optional, for demonstration)
    // const newMintAuthority = Keypair.generate().publicKey;  // Example: a new authority
    // await setAuthority(
    //   connection,
    //   publicKey,
    //   mint,
    //   publicKey,  // Current authority (owner)
    //   AuthorityType.MintTokens,
    //   newMintAuthority  // New authority for minting tokens
    // );

    // 5.Change the freeze authority (optional)
    // const newFreezeAuthority = Keypair.generate().publicKey;  // Example: a new freeze authority
    // await setAuthority(
    //   connection,
    //   publicKey,
    //   mint,
    //   publicKey,  // Current authority (owner)
    //   AuthorityType.FreezeAccount,
    //   newFreezeAuthority  // New authority for freezing accounts
    // );

    // alert(`Token created! Mint Address: ${mint.toBase58()}`);
    // // console.log("Mint Authority changed to:", newMintAuthority.toBase58());
    // console.log("Freeze Authority changed to:", newFreezeAuthority.toBase58());


    // let data = {
    //     mint : mint,
    //     tokenAccount:tokenAccount,
    //     mintTo : minto,
    //     newMintAuthority : newMintAuthority,
    //     newFreezeAuthority : newFreezeAuthority
    // }
    //   return data
  }
  return (
    <>

      {/* <button onClick={() => UsecreateTokenAndChangeAuthorities()}>Create Token & Change Authorities</button> */}

      <Col lg={6} xl={4} md={12} className={`mb-4 ${Classes.salesCardHolder}`}>
        <div className={`${Classes.salesCard} py-3 px-4`}>
          <img src={saleDBData?.logo ? saleDBData?.logo : Images.salesImg} className={`${Classes.salesCard__img} img-fluid mt-2`} alt='' />
          <button className={`${Classes.salesCard__topBtn} w-100 p-3 mt-3`}><p className={`${Classes.salesCard__gradText} roboto mx-auto text-capitalize`}>{tokeninfo?.name ? tokeninfo?.name : 'Meme of Neko'}</p></button>
          <ul className={`${Classes.salesCard__unorderList} mt-3`}>
            <li className={`${Classes.salesCard__lister} py-2 px-3`}>
              <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>{saleInfo?.launchType}</p>
            </li>
            <li className={`${Classes.salesCard__lister} py-2 px-3`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Soft Cap</p>
                <p className={`${Classes.listText} roboto`}>{saleInfo?.softCap / LAMPORTS_PER_SOL} {Config?.currency}</p>
              </div>
            </li>
            <li className={`${Classes.salesCard__lister} py-2 px-3`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Liquidity</p>
                <p className={`${Classes.listText} roboto`}>{duration(parseInt(saleInfo?.liquidityPercent))}%</p>
              </div>
            </li>
            <li className={`${Classes.salesCard__lister} py-2 px-3`}>
              <div className={`d-flex justify-content-between align-items-center`}>
                <p className={`${Classes.listText} ${Classes.listBeforeAlign} roboto`}>Lockup Time</p>
                <p className={`${Classes.listText} roboto`}>{duration(parseInt(saleInfo?.lockingDays))} Days</p>
              </div>
            </li>
          </ul>
          <div className={`${Classes.card__progressHolder}`}>

            {saleInfo?.launchType == "Launchpad" ||  saleInfo?.launchType == "Private Sale" ?
              <>
                <div className={`d-flex justify-content-between align-items-center`}>
                  <p className={`${Classes.card__progressTitle} poppins`}>Progress</p>
                  <p className={`${Classes.card__progressValue} roboto`}>{saleInfo && parseInt(parseInt(saleInfo?.currencyRaised) / parseInt(saleInfo?.hardCap) * 100)} %</p>
                </div>
                <div className={`${Classes.card__progressBorder} mt-2`}>
                  <div className={`${Classes.card__progressBar} position-relative`}>
                    <div className={`${Classes.card__filledProgress}`} style={{ width: `${saleInfo && parseInt((saleInfo?.currencyRaised) / (saleInfo?.hardCap) * 100)}%` }}></div>
                  </div>
                </div>
                <p className={`${Classes.card__progressValue} roboto mt-2`}>{saleInfo && ((saleInfo?.currencyRaised) / LAMPORTS_PER_SOL)} {Config?.Currency} raised</p></>
              : <>
                <div className={`d-flex justify-content-between align-items-center`}>
                  <p className={`${Classes.card__progressTitle} poppins`}>Progress</p>
                  <p className={`${Classes.card__progressValue} roboto`}>{saleInfo && percentage > 100 ? 100 : parseInt(parseInt(saleInfo?.currencyRaised) / parseInt(saleInfo?.softCap) * 100)}%</p>
                </div>
                <div className={`${Classes.card__progressBorder} mt-2`}>
                  <div className={`${Classes.card__progressBar} position-relative`}>
                    <div className={`${Classes.card__filledProgress}`} style={{ width: `${saleInfo && parseInt((saleInfo?.currencyRaised) / (saleInfo?.softCap) * 100)}%` }}></div>
                  </div>
                </div>

                <p className={`${Classes.card__progressValue} roboto mt-2`}>{saleInfo && ((saleInfo?.currencyRaised) / LAMPORTS_PER_SOL) / (parseInt(saleInfo?.softCap) / LAMPORTS_PER_SOL)} {Config?.Currency} raised</p></>
            }



            {saleInfo && saleHook.isUpcoming(parseInt(saleInfo?.presaleStart)) ?
              <>
                <p className={`${Classes.card__progressTitle} mt-4 roboto text-center text-capitalize`}>Sale Starts In</p>
                <div className={`${Classes.card__countdownHolder} p-3 d-flex justify-content-center position-relative mt-2`}>

                  <Countdown date={Date.now() + saleHook.Salediffernce(parseInt(saleInfo?.presaleStart))} renderer={renderer} />
                </div>
              </>
              :
              saleInfo && saleInfo?.ready !== 2 && saleHook.isSaleLive(saleInfo.presaleStart, saleInfo.presaleEnd) ?
                <>
                  <p className={`${Classes.card__progressTitle} mt-4 roboto text-center text-capitalize`}>Sale ends in</p>
                  <div className={`${Classes.card__countdownHolder} p-3 d-flex justify-content-center position-relative mt-2`}>

                    <Countdown date={Date.now() + saleHook.Salediffernce(parseInt(saleInfo?.presaleEnd))} renderer={renderer} />
                  </div>
                </>
                :
                // saleInfo && saleInfo?.ready == 0  || saleHook.isSaleEnded(parseInt(saleInfo?.presaleStart), parseInt(saleInfo?.presaleEnd)) ?
                <p className={`${Classes.card__progressTitle} mt-4 roboto text-center text-capitalize`}>Sale ended</p>
              // :<></>

            }

            <div className={`d-flex justify-content-center align-items-center mt-3 mb-3`}>
              <NavLink to={`/private-sale-detail/${saleData && (saleData?.publicKey)?.toString()}`}
                onClick={() => {
                  sessionStorage.setItem("saledata", JSON.stringify(saleData));
                  sessionStorage.setItem("saleDBdata", JSON.stringify(saleDBData))
                }}>

                <button className={`${Classes.card__blueBorderBtn} text-uppercase roboto`}>Detail</button>
              </NavLink>
            </div>

          </div>
        </div>
      </Col>

    </>
  )
}

export default PrivateSalesCard
