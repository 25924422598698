import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import BalanceDetails from '../../components/BalanceDetails'
import { Images } from '../../data/Images'
import Classes from '../../asset/styles/TokenLock.module.css'
import { Col, Row } from 'react-bootstrap'
import { commonData } from '../../data/CommonData'
import { NavLink } from 'react-router-dom'
import LaunchingTable from '../../components/LaunchingTable'
import NoData from '../../components/NoData'
import Uselock from '../../hooks/useLock'
import Usetoken from "../../hooks/useToken";
import { isEmpty } from '../../lib/isEmpty';
import { LAMPORTS_PER_SOL } from '@solana/web3.js'


const LockCard = ({ lockData, lockAdd, tab }) => {
    const lockHook = Uselock()
    const tokenHook = Usetoken()

    const [tokeninfo, setTokeninfo] = useState({})

    console.log("tab",tab)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        getTokenInfo()
    }, [lockData?.splMint])

    const getTokenInfo = async () => {
        console.log("tokeninfoooo", lockData?.splMint)
        if (!isEmpty(lockData?.splMint)) {
            let tokenInfo = await tokenHook.getTokenNameSymbol(lockData?.splMint)
            console.log("tokenInfo", tokenInfo)
            setTokeninfo(tokenInfo)
        }
    }


    return (
        <>



            <div className={`${Classes.cmnTableHolder} pb-3`}>
                <table className={`${Classes.tokenCmnTable} mt-5`}>
                    <thead>
                        <tr>
                            <th className='pb-3'>Token</th>
                            <th className='pb-3'>Amount</th>
                            <th className='pb-3'></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={`${Classes.gradientBorder}`}>
                            <td className='py-2'>
                                <div className={`${Classes.tokenDetails} d-flex justify-content-start align-items-center gap-2`}>
                                    <img src={tokeninfo?.image ? tokeninfo?.image : Images.solana} className={`${Classes.tableTokenImg} img-fluid`} alt='' />
                                    <div className={``}>
                                        <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>{tokeninfo ? tokeninfo?.name : ""}</p>
                                        <p className={`${Classes.tableSubHint} poppins m-0 position-relative`}>{tokeninfo ? tokeninfo?.symbol : ""}</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>{lockData?.amountLocked / LAMPORTS_PER_SOL} {tokeninfo ? tokeninfo?.symbol : ""}</p>
                            </td>
                            {
                                tab == 'All' ?
                                    <td className={`text-end`}>
                                        <NavLink to={`/lock-detail/${lockAdd}`} className={`poppins cmnNavLink position-relative px-3 ${Classes.tableNavLink}`}>View</NavLink>
                                    </td>
                                    :
                                    <td className={`text-end`}>
                                        <NavLink to={`/mylock-detail/${lockAdd}`} className={`poppins cmnNavLink position-relative px-3 ${Classes.tableNavLink}`}>View</NavLink>
                                    </td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default LockCard
