import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Images } from '../../data/Images'
import Classes from '../../asset/styles/LockDetail.module.css'
import { Col, Row } from 'react-bootstrap'
import BalanceDetails from '../../components/BalanceDetails'
import { useParams } from "react-router-dom";
import Uselock from '../../hooks/useLock'
import Usetoken from "../../hooks/useToken";
import { isEmpty } from '../../lib/isEmpty';
import { LAMPORTS_PER_SOL } from '@solana/web3.js'

const LockDetail = () => {

    const params = useParams()
    const lockHook = Uselock()
    const tokenHook = Usetoken()

    const [lockAdd, setLockAdd] = useState('')
    const [lockInfo, setLockInfo] = useState({})
    const [tokeninfo, setTokeninfo] = useState({})


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const getLockData = async () => {
        let datas = await lockHook?.UseLockInfo(params?.lockAddress)
        console.log("getLock_LockDetail", datas, datas?.unlockOn.toString(), new Date(lockInfo?.unlockOn?.toString()))
        setLockAdd(params?.saleAddress)
        setLockInfo(datas)
    }

    useEffect(() => {
        getLockData()
    }, [])


    const getTokenInfo = async () => {
        console.log("tokeninfoooo", lockInfo?.splMint)
        if (!isEmpty(lockInfo?.splMint)) {
            let tokenInfo = await tokenHook.getTokenNameSymbol(lockInfo?.splMint)
            console.log("tokenInfo", tokenInfo)
            setTokeninfo(tokenInfo)
        }
    }

    useEffect(() => {
        getTokenInfo()
    }, [lockInfo?.splMint])


    return (
        <>
            <Layout>
                <section className="custom_section position-relative">
                    <img src={Images.lighting} className={`${Classes.absLighting} img-fluid`} alt="" />
                    <BalanceDetails />

                    <Row className={`mx-auto ${Classes.createToken__emptyRow} pb-5 position-relative`}>
                        <Col lg={{ span: 8, offset: 2 }} xs={12} className={`px-lg-0 ${Classes.dash__emptyBorder}`}>
                            <div className={`${Classes.tokenCreationHolder} px-2 px-sm-3 px-md-4 px-lg-5 py-5`}>
                                <h2 className={`${Classes.launchpad__title} text-uppercase aboro_font`}>Lock Info</h2>

                                <div className={`${Classes.flexHolder}`}>
                                    <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                                        <p className={`${Classes.flexLabel} m-0 roboto`}>Current Locked Amount</p>
                                        <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo?.amountLocked / LAMPORTS_PER_SOL}</p>
                                    </div>
                                    <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                                        <p className={`${Classes.flexLabel} m-0 roboto`}>Token Address</p>
                                        <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo?.splMint?.toString()}</p>
                                    </div>
                                    <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                                        <p className={`${Classes.flexLabel} m-0 roboto`}>Token Name</p>
                                        <p className={`${Classes.flexValue} roboto m-0`}>{tokeninfo ? tokeninfo?.name : ""}</p>
                                    </div>
                                    <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                                        <p className={`${Classes.flexLabel} m-0 roboto`}>Token Symbol</p>
                                        <p className={`${Classes.flexValue} roboto m-0 text-uppercase`}>{tokeninfo ? tokeninfo?.symbol : ""}</p>
                                    </div>
                                    <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                                        <p className={`${Classes.flexLabel} m-0 roboto`}>Token Decimals</p>
                                        <p className={`${Classes.flexValue} roboto m-0`}>{tokeninfo ? tokeninfo?.decimal : ""}</p>
                                    </div>
                                    <h2 className={`${Classes.launchpad__subTitle} text-uppercase aboro_font mt-5`}>Lock Records</h2>
                                    <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                                        <p className={`${Classes.flexLabel} m-0 roboto`}>Created User</p>
                                        <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo?.authority?.toString()}</p>
                                    </div>

                                    <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                                        <p className={`${Classes.flexLabel} m-0 roboto`}>Owner</p>
                                        <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo?.reciever?.toString()}</p>
                                    </div>
                                    <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                                        <p className={`${Classes.flexLabel} m-0 roboto`}>Amount</p>
                                        <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo?.amountLocked / LAMPORTS_PER_SOL}</p>
                                    </div>
                                    <div className={`${Classes.singleFlex} d-flex justify-content-between align-items-center p-3`}>
                                        <p className={`${Classes.flexLabel} m-0 roboto`}>Unlock Time(UTC)</p>
                                        <p className={`${Classes.flexValue} roboto m-0`}>{lockInfo && new Date(parseInt(lockInfo?.unlockOn) * 1000).toLocaleDateString() + "  "
                                            + new Date(parseInt(lockInfo?.unlockOn) * 1000).getHours() + ":" + new Date(parseInt(lockInfo?.unlockOn) * 1000).getMinutes() + ":"
                                            + new Date(parseInt(lockInfo?.unlockOn) * 1000).getSeconds()}</p>
                                    </div>
                                </div>
                            </div>


                        </Col>
                    </Row>
                    {/* <LaunchingTable/> */}
                </section>
            </Layout>

        </>
    )
}

export default LockDetail
