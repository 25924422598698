import React, { useState, useEffect } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import Classes from "../../../asset/styles/AdminLaunchpad.module.css";
import { NavLink } from "react-router-dom";
import { commonData } from "../../../data/CommonData";
import { Images } from "../../../data/Images";
import { CiGlobe } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import Usesale from "../../../hooks/useSale";
import { getallsalehook } from "../../../actions/userAction";
import { useSelector } from "react-redux";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { isEmpty } from "../../../lib/isEmpty";
import Usetoken from "../../../hooks/useToken";
import AdminLaunchpadList from "./AdminLaunchpadList";
import { EditSalestatus } from "../../../actions/adminAction";

const AdminLaunchpad = () => {

  const saleHook = Usesale()
  const tokenHook = Usetoken()

  const [saleData, setSaleData] = useState({})
  const [saleDBData, setSaleDBData] = useState({})
  const [interval, setInterval] = useState(1)
  const [loadInterval, setLoadInterval] = useState(3)
  const [loadData, setLoadData] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [skip, setSkip] = useState(0)
  console.log("sdfewwsvfwse", loadData)

  const getSaleInfo = async () => {
    let TotalData = await saleHook?.UseTotalSaleInfo()
    console.log("getSale_admi_dewskipn", skip, loadInterval)
    setSaleData(TotalData)
    let getSale = [...TotalData]
    let start = skip > 0 ? 0 : skip;
    let end = skip > 0 ? skip + 3 : loadInterval
    let limit = [...getSale].splice(start, end);
    console.log("newlynewlynewly", TotalData, limit, skip, loadInterval)
    console.log("getSale_admin2", getSale, limit, getSale.splice(interval, loadInterval))
    setLoadData(limit)

    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb_admin", getSalefromDb)
    setSaleDBData(getSalefromDb?.data?.data)
  }

  useEffect(() => {
    window.scrollTo({
      bottom: 0,
      behavior: "smooth"
    });
  }, [loadData]);

  useEffect(() => {
    getSaleInfo()
  }, [status])


  const loadMore = async (interval) => {
    console.log("Load_moreee", saleData, loadInterval)
    setIsLoading(true);
    setInterval(interval)
    let skip = (interval - 1) * loadInterval
    const newly = [...saleData];
    console.log("qqq newlynewlynewly", newly, skip, loadInterval)
    const limit = newly.splice(skip, loadInterval)
    // console.log("newly", loadData, limit, skip, loadInterval);
    setSkip(skip)
    setIsLoading(false);
    setLoadData([...loadData, ...limit])
  }






  return (
    <>
      <AdminLayout>

        <div className={`${Classes.adminChangeCont} p-2 p-sm-3 p-md-4 p-lg-5`}>
          <h2
            className={`${Classes.launchpad__title} text-uppercase aboro_font`}
          >
            Launchpad and Privatesale List
          </h2>


          <div className={`${Classes.cmnTableHolder} pb-3`}>
            {saleDBData && loadData?.length > 0 ?
              <table className={`${Classes.tokenCmnTable} mt-5`}>
                <thead>
                  <tr>
                    <th className="pb-3">Name</th>
                    <th className="pb-3">Sale Type</th>
                    <th className="pb-3">Softcap</th>
                    <th className="pb-3">Hardcap</th>
                    <th className="pb-3">Badge</th>
                    <th className="pb-3">Status</th>
                    <th className="pb-3">Links</th>
                    <th className="pb-3">Starts in</th>
                    <th className="pb-3">Ends in</th>
                    <th className="pb-3">Edit</th>
                  </tr>
                </thead>
                <tbody>

                  {saleDBData && loadData?.map((value, index) => {
                    console.log("value_value", value, value.account)
                    let saleInfo = value.account
                    let DBData = {}

                    if (saleDBData.length > 0) {
                      DBData = saleDBData?.find((val) => (val?.saleAddress == value?.publicKey?.toString()))
                      console.log("DBData", DBData)
                    }
                    return (
                      <AdminLaunchpadList DBData={DBData} saleInfo={saleInfo} saleAddress={value} setStatusData={() => setStatus(!status)} />
                    )
                  })}




                </tbody>
              </table>
              : <p className="text-white text-center roboto mt-5">No Data Found</p>
            }

          </div>
          {saleData?.length > loadData?.length ?
            <div className={`text-center w-100 mt-5`}>
              <button className={`${Classes.violetBtn} px-2 px-sm-3 px-md-4`} onClick={() => { loadMore(interval + 1) }}>
                <p
                  className={`${Classes.violetBtnText} text-uppercase position-relative roboto`}
                >
                  {isLoading ? 'View More Pools...' : 'View More Pools'}
                </p>
              </button>
            </div>
            : <div> </div>
          }
        </div>
      </AdminLayout>
    </>
  );
};

export default AdminLaunchpad;
