/** npm import */

import React, { useEffect, useRef, useState } from "react";
import HeaderMarquee from "../../layout/HeaderMarquee";
import Header from "../../layout/Header";
import { Images } from "../../data/Images";
import Classes from "../../asset/styles/Dashboard.module.css";
import LaunchingTable from "../../components/LaunchingTable";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import { commonData } from "../../data/CommonData";
import Layout from "../../layout/Layout";
import { Col, Container, Row } from "react-bootstrap";
import ConnectWalletModal from "../../Modals/ConnectWalletModal";
import { NavLink } from "react-router-dom";
import { Element } from "react-scroll";
import { getAllCMSData } from "../../actions/cmsActions";
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from '../../lib/isEmpty';
import Walletbutton from "../../components/walletbutton";
import HookFunction from "../../hooks/useStorage"
import PremiumCard from "../Launchpad/premiumCard";
import Usesale from "../../hooks/useSale";
import { getallsalehook } from "../../actions/userAction";
import Uselock from "../../hooks/useLock";


/** local files import */

/** code start */

const Dashboard = () => {
  const swiperRef = useRef();
  const saleHook = Usesale()
  const lockHook = Uselock()

  const [saleData, setSaleData] = useState({})
  const [saleDBData, setSaleDBData] = useState({})
  const [ownerAdd, setOwnerAdd] = useState("")
  const [premium, setPremium] = useState({})

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [CMSdata, setCMSdata] = useState({})
  const [saleCount,setSaleCount] = useState(0)
  const [lockCount,setLockCount] = useState(0)

  // connect wallet state

  const [showWallet, setShowWallet] = useState(false);
  const handleShowWallet = () => setShowWallet(true);
  const handleCloseWallet = () => setShowWallet(false);
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [wallet, setWallet] = useState(false)

  const storageHooks = HookFunction()



  const walletdetail = useSelector((state) => state.wallet)
  console.log("walletdetail_ballance", walletdetail)

  useEffect(() => {
    storageHooks.getWalletAddress()

  }, [walletdetail])

  // swiper next func
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // swiper prev func
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  // swiper button disable
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.on("slideChange", () => {
        setIsBeginning(swiperRef.current.swiper.isBeginning);
        setIsEnd(swiperRef.current.swiper.isEnd);
      });

      // Set initial state
      setIsBeginning(swiperRef.current.swiper.isBeginning);
      setIsEnd(swiperRef.current.swiper.isEnd);
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let { status, message, result } = await getAllCMSData();
      if (status) {
        setCMSdata(result)
      }

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSaleInfo()
    getOwner()
  }, [ownerAdd])


  const getOwner = async () => {
    const owner = await saleHook?.getSaleOwner()
    console.log("owner", owner)
    setOwnerAdd(owner)
  }


  const getSaleInfo = async () => {
    let TotalData = await saleHook?.UseTotalSaleInfo()
    console.log("getSale", TotalData)
    setSaleData(TotalData)
    setSaleCount(TotalData?.length)
    const premiumfilter = TotalData?.filter((val) => {
      console.log("premiumfilter_val", val?.account?.authority.toString(), val?.account?.authority.toString() == ownerAdd && val?.account?.isWhitelist == false);
      return val?.account?.authority.toString() == ownerAdd && val?.account?.isWhitelist == false
    })
    console.log("premiumfilter123", premiumfilter)
    setPremium(premiumfilter)

    // let getSale = [...TotalData]
    // let limit = [...getSale].splice(0, loadInterval)
    // console.log("getSale", getSale, limit, getSale.splice(interval, loadInterval))
    // setLoadData(limit)

    const getSalefromDb = await getallsalehook()
    console.log("getSalefromDb", getSalefromDb)
    setSaleDBData(getSalefromDb?.data?.data)
  }


  useEffect(()=>{
    getLockData()
 },[])


 const getLockData = async() =>{
  let datas = await lockHook?.UseTotalLockInfo()
  console.log("getLock_Count", datas, datas?.length)
  setLockCount(datas?.length)
}



  return (
    <>
      <Layout>
        <div className={``}>
          <section className="custom_section position-relative">
            <img
              src={Images.lighting}
              className={`${Classes.absLighting} img-fluid`}
              alt=""
            />
            <Row className={`mx-auto ${Classes.dash__emptyRow}`}>
              <Col
                xs={6}
                lg={{ span: 4, offset: 2 }}
                className={`${Classes.dash__emptyBorder}`}></Col>
              <Col
                xs={6}
                lg={4}
                className={`${Classes.dash__emptyBorder}`}></Col>
              <Col
                xs={0}
                lg={2}
                className={`d-none d-lg-flex ${Classes.dash__emptyBorder}`}></Col>
            </Row>
            <Row className={`mx-auto`}>
              <Col
                md={6}
                lg={{ span: 4, offset: 2 }}
                className={`p-0 ${Classes.dash__bannerLeft}`}>
                <img
                  src={Images.solmax}
                  className={`${Classes.dash__solmax}`}
                  alt=""
                />

                <div
                  className={`${Classes.dash__dualBox} d-flex justify-content-start align-items-center mt-5`}>
                  <p
                    className={`${Classes.dash__dualBoxText} ${Classes.maintxt} text-uppercase mt-5 ms-5 roboto`}>
                    {CMSdata && CMSdata[0]?.content}
                  </p>
                </div>

                <div
                  className={`${Classes.dash__dualBtnsHolder} mt-4 d-flex justify-content-start align-items-center gap-4 flex-lg-column flex-xl-row`}>
                  <button
                    className={`${Classes.dash__violetLeftBtn} text-uppercase px-4 py-3 roboto`}>
                    launchpad learn
                  </button>
                  <button
                    className={`${Classes.dash__violetBtn} text-uppercase px-4 py-3 roboto`}>
                    launching now
                  </button>
                </div>
              </Col>
              <Col md={6} className={``}>
                <Row className={`position-relative`}>
                  <img
                    src={Images.bannerclif}
                    className={`img-fluid ${Classes.bannerClifImg}`}
                    alt=""
                  />

                  <Col
                    xs={0}
                    sm={6}
                    lg={8}
                    className={`p-0 d-none d-sm-flex ${Classes.dash__bannerLeft}`}></Col>
                  <Col
                    lg={2}
                    className={`d-none d-lg-flex  ${Classes.dash__bannerLeft}`}></Col>
                </Row>
              </Col>
            </Row>
            <Element name="premiumSale">
              <Container className={`custom_container`}>
                <Row className={`justify-content-between`}>
                  <Col lg={4} xl={4} xxl={3} className={`mb-5 mb-lg-0`}>
                    <div
                      className={`${Classes.dash__leftListHolder} ${Classes.dashClipGapper} h-100`}>
                      <p
                        className={`${Classes.yourBalance} text-uppercase m-0 ps-4 roboto py-3 position-relative`}>
                        Your Balance
                      </p>

                      <ul
                        className={`${Classes.salesCard__unorderList} mt-3 position-relative`}>
                        <li className={`${Classes.gray__lister} py-2 px-3`}>
                          <p
                            className={`${Classes.listTextWhite} ${Classes.listBeforeAlign} roboto`}>
                            SOL Available :{!isEmpty(walletdetail?.walletData?.walletBal) ? walletdetail?.walletData?.walletBal : 0}
                          </p>
                        </li>
                        <li className                                                                                                                                                                                             ={`${Classes.gray__lister} py-2 px-3`}>
                          <div
                            className={`d-flex justify-content-between align-items-center`}>
                            <p
                              className={`${Classes.listTextWhite} ${Classes.listBeforeAlign} roboto`}>
                              Sale Participation : {!isEmpty(saleCount) ? saleCount : 0}
                            </p>
                          </div>
                        </li>
                        <li className={`${Classes.gray__lister} py-2 px-3`}>
                          <div
                            className={`d-flex justify-content-between align-items-center`}>
                            <p
                              className={`${Classes.listTextWhite} ${Classes.listBeforeAlign} roboto`}>
                              Token Lock : {!isEmpty(lockCount) ? lockCount : 0}
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div
                        className={`d-flex justify-content-center align-items-center py-3`}>

                        {/* <button
                          className={`${Classes.launchpad__blueBtn} text-uppercase roboto`}
                          onClick={handleShowWallet}>
                          <i
                            class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`}
                          />
                          <i
                            class={`fa-solid fa-circle me-3 ${Classes.connectWalDots}`}
                          />
                          Connect Wallet
                        </button> */}

                        {/* {isEmpty(walletdetail.walletaddress) ?
                          <button
                            className={`${Classes.launchpad__blueBtn} text-uppercase roboto`}
                            onClick={handleShow}
                          >
                            <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
                            <i class={`fa-solid fa-circle me-3 ${Classes.connectWalDots}`} />
                            Connect Wallet
                          </button>
                          : <button
                            className={`${Classes.launchpad__blueBtn} text-uppercase roboto`} disabled
                          // onClick={handleShow} 
                          >
                            <i class={`fa-solid fa-circle me-1 ${Classes.connectWalDots}`} />
                            <i class={`fa-solid fa-circle me-3 ${Classes.connectWalDots}`} />
                            {`${(walletdetail?.walletaddress).substring(0, 5)}...${walletdetail?.walletaddress.substring(38, 42)}`}
                          </button>
                        } */}

                        <Walletbutton />


                      </div>
                    </div>
                  </Col>

                  <Col lg={8} xl={8} xxl={9} className={`h-100`}>
                    <Row
                      className={`${Classes.dash__leftListHolder} position-relative h-100`}>

                      {premium && premium?.length > 0 ? premium?.map((val,i) => {
                        console.log("val", val)
                        if (i == 0) {
                        let premiumLaunch = val?.account
                        let DBData = {}
                        if (saleDBData?.length > 0 && val?.account?.isWhitelist == false) {
                          DBData = saleDBData?.find((items) => (items?.saleAddress == val?.publicKey?.toString()))
                          console.log("DBData_premium", DBData)

                        }
                        return (

                          <PremiumCard DBData={DBData} premiumLaunch={premiumLaunch} val={val} />
                        )
                      }
                      })
                        :
                        <p className={`${Classes.yourBalance} text-center d-flex align-items-center justify-content-center text-uppercase roboto`}>Currently Premium sale is not available</p>}
                      {/* <Col lg={8} className={``}>
                        <div className={`p-4`}>
                          <img
                            src={Images.clip}
                            className={`img-fluid ${Classes.leftTopClip}`}
                            alt=""
                          />
                          <img
                            src={Images.clip}
                            className={`img-fluid ${Classes.leftBottomClip}`}
                            alt=""
                          />
                          <img
                            src={Images.clip}
                            className={`img-fluid ${Classes.rightTopClip}`}
                            alt=""
                          />
                          <img
                            src={Images.clip}
                            className={`img-fluid ${Classes.rightBottomClip}`}
                            alt=""
                          />
                          <div className={`${Classes.launchpad__nameHolder}`}>
                            <h1
                              className={`${Classes.cmnBigTitle} m-0 text-uppercase aboro_font`}>
                              neko meme
                            </h1>
                          </div>
                          <p
                            className={`${Classes.launchpad__desc} ${Classes.responsiveWidText} m-0 text-uppercase mt-5 pb-5 roboto`}>
                            Meme of Neko is not only meme project on solana, we
                            also provide NFT Staking. Staking NFT link :
                            https://nft.mekosolana.com. Buy presale, Earn
                            passive income by staking your Meko NFT in our
                            platform.
                          </p>
                          <NavLink to="/premium-sale-detail">
                            <button
                              className={`${Classes.dash__noseBgBtn} text-uppercase py-2 px-5 roboto`}>
                              sale view
                            </button>
                          </NavLink>
                        </div>
                      </Col>


                      <Col lg={4} className={`pe-1`}>
                        <div
                          className={`d-flex justify-content-between flex-column align-items-start`}>
                          <img
                            src={Images.salesImg}
                            className={`img-fluid ${Class/ 1 /
{
    "_id" : ObjectId("66d7035ae16c1c374c1c1803"),
    "identifier" : "SOLMAX",
    "content" : "launchpad for solana projects",
    "updatedAt" : ISODate("2024-10-28T09:26:07.252Z"),
    "title" : ""
}

/ 2 /
{
    "_id" : ObjectId("66d7e78aa78d4589f6931814"),
    "identifier" : "NEKO",
    "content" : "MEME OF NEKO IS NOT ONLY MEME PROJECT ON SOLANA, WE ALSO PROVIDE NFT STAKING. STAKING NFT LINK : HTTPS://nft.mekosolana.com. BUY PRESALE, EARN PASSIVE INCOME BY STAKING YOUR MEKO NFT IN OUR PLATFORM.",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : "MEME"
}

/ 3 /
{
    "_id" : ObjectId("66d7e7e8a78d4589f6931815"),
    "identifier" : "LAUNCHING",
    "content" : "ONLY FOR PREMIUM PROJECTS WITH MANUALLY APPROVAL FROM SOLMAX TEAM. CONTACT OUR TEAM FOR FURTHER CONSULTANTS.",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : ""
}

/ 4 /
{
    "_id" : ObjectId("66d7eaeaa78d4589f6931816"),
    "identifier" : "premium launch",
    "content" : "Direct support from SOLMAX team with a full batch of all services.",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : ""
}

/ 5 /
{
    "_id" : ObjectId("66d7eb51a78d4589f6931817"),
    "identifier" : "standard",
    "content" : "mint standard tokens on solana.",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : ""
}

/ 6 /
{
    "_id" : ObjectId("66d7eb58a78d4589f6931818"),
    "identifier" : "customization",
    "content" : "create a toke sale for your own custom token easily.",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : ""
}

/ 7 /
{
    "_id" : ObjectId("66d7eb7ca78d4589f6931819"),
    "identifier" : "Deflationary",
    "content" : "Generate deflationary tokens with tax and/or charity functions.",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : ""
}

/ 8 /
{
    "_id" : ObjectId("66d7eb94a78d4589f693181a"),
    "identifier" : "Launchpad",
    "content" : "Use the token you mint to create a launchpad with just a few clicks",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : ""
}

/ 9 /
{
    "_id" : ObjectId("66d7ebcaa78d4589f693181b"),
    "identifier" : "Branding",
    "content" : "Adding logo, social links, description, listing on Flashpad",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : ""
}

/ 10 /
{
    "_id" : ObjectId("66d7ed83a78d4589f693181c"),
    "identifier" : "Management",
    "content" : "The portal to help you easily update content for your launchpad.",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : ""
}

/ 11 /
{
    "_id" : ObjectId("66d7edb1a78d4589f693181d"),
    "identifier" : "Community",
    "content" : "Promote your launchpad to thousands of buyers on Flashpad.",
    "updatedAt" : ISODate("2024-09-03T13:41:32.978Z"),
    "title" : ""
}

/ 12 /
{
    "_id" : ObjectId("66d7edd9a78d4589f693181e"),
    "identifier" : "Locking",
    "content" : "Lock your liquidity to Flashpad Swap after presale.",
    "updatedAt" : ISODate("2024-10-08T10:35:38.925Z"),
    "title" : ""
}
                          <ul
                            className={`${Classes.salesCard__unorderList} flex-grow-1 w-100 mb-0`}>
                            <li
                              className={`${Classes.salesCard__lister} py-2 px-3`}>
                              <div
                                className={`d-flex justify-content-between align-items-center`}>
                                <img
                                  src={Images.triangle}
                                  className={`img-fluid ${Classes.rotatedArrow}`}
                                />
                                <p className={`${Classes.listText} roboto`}>
                                  Premium Gem
                                </p>
                              </div>
                            </li>
                            <li
                              className={`${Classes.salesCard__lister} py-2 px-3`}>
                              <div
                                className={`d-flex justify-content-between align-items-center`}>
                                <img
                                  src={Images.triangle}
                                  className={`img-fluid ${Classes.rotatedArrow}`}
                                />
                                <p
                                  className={`${Classes.listText} roboto text-uppercase`}>
                                  KYC
                                </p>
                              </div>
                            </li>
                            <li
                              className={`${Classes.salesCard__lister} py-2 px-3`}>
                              <div
                                className={`d-flex justify-content-between align-items-center`}>
                                <img
                                  src={Images.triangle}
                                  className={`img-fluid ${Classes.rotatedArrow}`}
                                />
                                <p className={`${Classes.listText} roboto`}>
                                  Audit
                                </p>
                              </div>
                            </li>
                            <li
                              className={`${Classes.salesCard__lister} py-2 px-3`}>
                              <div
                                className={`d-flex justify-content-between align-items-center`}>
                                <img
                                  src={Images.triangle}
                                  className={`img-fluid ${Classes.rotatedArrow}`}
                                />
                                <p
                                  className={`${Classes.listText} roboto text-uppercase`}>
                                  safu
                                </p>
                              </div>
                            </li>
                            <li
                              className={`${Classes.salesCard__lister} py-2 px-3`}>
                              <div
                                className={`d-flex justify-content-between align-items-center`}>
                                <img
                                  src={Images.triangle}
                                  className={`img-fluid ${Classes.rotatedArrow}`}
                                />
                                <p className={`${Classes.listText} roboto`}>
                                  Doxx
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Col> */}


                    </Row>
                  </Col>
                </Row>
              </Container>
            </Element>

            <div className={`mt-5`}>
              <LaunchingTable />
            </div>

            <Container fluid className={`mb-5 px-0 mt-5`}>
              <div
                className={`d-flex justify-content-between align-items-center flex-column flex-sm-row gap-5 gap-sm-0`}>
                <button
                  className={`${Classes.dash__bingNoseBtn} px-2 py-3 d-flex justify-content-center`}>
                  <p
                    className={`${Classes.dash__bingNoseBtnText} text-uppercase aboro_font text-center`}>
                    sale tool
                  </p>
                </button>

                <div
                  className={`${Classes.swiperBtnsHolder} d-flex justify-content-end align-items-center gap-3 me-5`}>
                  <button
                    className={`${Classes.swiperPrev}`}
                    onClick={() => goPrev()}
                    disabled={isBeginning}>
                    <img
                      src={Images.buttonNext}
                      className={`${Classes.swiperLeftArrow} img-fluid`}
                    />
                  </button>
                  <button
                    className={`${Classes.swiperPrev}`}
                    onClick={() => goNext()}
                    disabled={isEnd}>
                    <img
                      src={Images.buttonNext}
                      className={`${Classes.swiperRighttArrow}`}
                    />
                  </button>
                </div>
              </div>

              <div className={`${Classes.dash__swiperHolder} mt-4 py-4`}>
                <Swiper
                  modules={[Autoplay]}
                  className={`${Classes.mySwiper}`}
                  ref={swiperRef}
                  // autoplay={{
                  //   delay: 1500,
                  //   disableOnInteraction: false,
                  // }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    576: {
                      slidesPerView: 1.2,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 2.3,
                    },
                    1200: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 3.3,
                    },
                    1600: {
                      slidesPerView: 4,
                    },
                  }}>
                  {/* {commonData?.dashboardSwiper?.map((item) => (
                    <SwiperSlide>
                      <div className={`${Classes.dash__singleSlide} px-4`}>
                        <div
                          className={`${Classes.clipTextHolder} position-relative`}>
                          <img
                            src={Images.clip}
                            className={`img-fluid ${Classes.slideLeftTopClip}`}
                            alt=""
                          />
                          <img
                            src={Images.clip}
                            className={`img-fluid ${Classes.sliderightBottomClip}`}
                            alt=""
                          />
                          <p
                            className={`${Classes.dash__slideClipText} m-0 text-uppercase roboto py-3`}>
                            {item.title}
                          </p>
                        </div>

                        <p
                          className={`${Classes.dash__slideHint} m-0 text-uppercase roboto mt-3 w-75`}>
                          {item.hint}
                        </p>
                      </div>
                    </SwiperSlide>
                  ))} */}


                  {CMSdata?.length > 0 && CMSdata?.map((item, index) => (


                    index == 3 || index == 4 || index == 5 || index == 6 ||
                      index == 7 || index == 8 || index == 9 || index == 10 ||
                      index == 11 || index == 12 ?
                      <>
                        <SwiperSlide>
                          <div className={`${Classes.dash__singleSlide} px-4`}>
                            <div
                              className={`${Classes.clipTextHolder} position-relative`}>
                              <img
                                src={Images.clip}
                                className={`img-fluid ${Classes.slideLeftTopClip}`}
                                alt=""
                              />
                              <img
                                src={Images.clip}
                                className={`img-fluid ${Classes.sliderightBottomClip}`}
                                alt=""
                              />
                              <p
                                className={`${Classes.dash__slideClipText} m-0 text-uppercase roboto py-3`}>
                                {item.identifier}
                              </p>
                            </div>

                            <p
                              className={`${Classes.dash__slideHint} m-0 text-uppercase roboto mt-3 w-75`}>
                              {item.content}
                            </p>
                          </div>

                        </SwiperSlide></> : <></>

                  ))}
                </Swiper>
              </div>
            </Container>
          </section>
        </div>
      </Layout>

      {/* modal content */}
      <ConnectWalletModal
        show={showWallet}
        handleClose={handleCloseWallet}
        wallet={wallet}
        setWallet={setWallet}
      />
      {/* end of modal content */}
    </>
  );
};

export default Dashboard;
