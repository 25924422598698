import axios from "../config/axios";

export const updateKYCDetails = async (data) => {

  console.log("updateKYCDetails", data);

  try {
    let respData = await axios({
      method: "post",
      url: `/user/kyc-details`,
      data,
    });
    console.log("resodasdfasrferfwe", respData)
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const getUserKycDetails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/user/getuserKyc`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
      data: null,
    };
  }
};

export const createuserhook = async (data) => {
  console.log("login data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("walletaddress", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/addnewuser`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      console.log("createuserhook__error", console)
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("createuserhook__error", e);
  }
}


export const getuserdatahook = async (data) => {
  console.log("login data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("walletaddress", data);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/getuserdata`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const userlaunchpadhook = async (data) => {
  console.log("login data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("saleAddress", data?.saleAddress);
      formdata.append("walletAddress", data?.walletAddress);
      formdata.append("tokenAddress", data?.tokenAddress);
      formdata.append("launchType", data?.launchType);
      formdata.append("description", data?.description);
      formdata.append("logo", data?.logo);
      formdata.append("banner", data?.banner);
      formdata.append("website", data?.website);
      formdata.append("youtube", data?.youtube);
      formdata.append("twitter", data?.twitter);
      formdata.append("telegram", data?.telegram);
      formdata.append("discord", data?.discord);
      formdata.append("github", data?.github);
      formdata.append("instagram", data?.instagram);
      formdata.append("reddit", data?.reddit);
      formdata.append("name", data?.name);
      formdata.append("symbol", data?.symbol);


      // formdata.append("chain", CHAINS[getChainId()].CHAIN_ID)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/userlaunchpad`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const edituserlaunchpad = async (data) => {
  console.log("edituserlaunchpad", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("saleAddress", data?.saleAddress);
      formdata.append("description", data?.description);
      formdata.append("logo", data?.logo);
      formdata.append("banner", data?.banner);
      formdata.append("website", data?.website);
      formdata.append("youtube", data?.youtube);
      formdata.append("twitter", data?.twitter);
      formdata.append("telegram", data?.telegram);
      formdata.append("discord", data?.discord);
      formdata.append("github", data?.github);
      formdata.append("instagram", data?.instagram);
      formdata.append("reddit", data?.reddit);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/editlaunchpad`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const userinvestedhook = async (data) => {
  console.log("login data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("saleaddress", data?.saleaddress);
      formdata.append("walletaddress", data?.walletaddress);
      formdata.append("amount", data?.amount);
      // formdata.append("chain", CHAINS[getChainId()].CHAIN_ID)
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/userinvested`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const getallsalehook = async () => {
  try {
    let respData = await axios.get(`/user/getallsale`)

    console.log("respdata", respData);
    return respData
  }
  catch (err) {
    return {
      error: err
    }
  }
}



export const useaddWhiteListSale = async (data) => {
  console.log("login data", data);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("presaleAddress", data?.sale);
      formdata.append("isWhiteListed", data?.isWhiteListed)

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/addWhiteListSale`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const useaddWhiteListUser = async (data) => {
  console.log("login data", data, data?.whiteListData);
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("presaleAddress", data?.presaleAddress);
      formdata.append("whiteListData", JSON.stringify(data?.whiteListData))
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/addWhiteListUser`,
        'credentials': true,
        'headers': {
          'content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const GetWhiteListUser = async (walletAddress, presaleAddress) => {
  console.log("GetWhiteListUser", walletAddress, presaleAddress);
  try {
    const GetWhiteListUser = await axios.get(`/user/getwhiteListuser/${walletAddress}/${presaleAddress}`)
    console.log("GetWhiteListUser", GetWhiteListUser?.data?.data);
    return GetWhiteListUser?.data?.data;
  } catch (error) {
    console.log("GetWhiteListUsererror", error);
  }
}



export const GetWhiteListSale = async (presaleAddress) => {
  try {
    const GetWhiteListSale = await axios.get(`/user/getwhiteListsale/${presaleAddress}`)
    console.log("GetWhiteList", GetWhiteListSale?.data?.data);
    return GetWhiteListSale?.data?.data;
  } catch (error) {
    console.log("GetWhiteListSalweerror", error);
  }
}


export const EditWhiteListStatus = async (data) => {
  console.log("login data", (data?.status));
  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("presaleAddress", data?.presaleAddress);
      formdata.append("status", data?.status);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/editwhitelistStatus`,

        data: data,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}

export const createToken = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/user/createToken`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response,
      error: err.response,
      data: null,
    };
  }
};


export const EditWhiteListBuyAmount = async (data) => {
  console.log("editBuyAmount>>>>>>>>>>>", data);

  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("presaleAddress", data?.presaleAddress);
      formdata.append("walletAddress", data?.walletAddress);
      formdata.append("buyAmount", data?.buyAmount);

    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/editBuyamount`,

        data: data,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}



export const RemoveWhiteListUser = async (data) => {
  console.log("removeUserresponse", data);

  var formdata = new FormData();
  try {
    if (data) {
      formdata.append("presaleAddress", data?.presaleAddress);
      formdata.append("walletAddress", data?.walletAddress);
    }
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': `/user/removewhitelistUser`,

        data: data,
      });
      console.log("response", respdata);
      return {
        data: respdata.data
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }
  catch (e) {
    console.log("error", e);
  }
}


export const GetsingleSale = async (presaleAddress) => {
  console.log("saleinfosaleinfo", presaleAddress);

  try {
    const data = await axios.get(`/user/getsinglesale/${presaleAddress}`)
    console.log("saleinfosaleinfo", data);
    return data;
  } catch (error) {
    console.log("GetWhiteListSalweerror", error);
  }
}