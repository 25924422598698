/** npm import */

import React, { useEffect, useState } from "react";

/** local files import */
import { CiGlobe } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import SalesCard from "../../../components/SalesCard";
import { Images } from "../../../data/Images";
import { commonData } from "../../../data/CommonData";
import LaunchingTable from "../../../components/LaunchingTable";
import Countdown from 'react-countdown';
import { Col, Container, Row } from "react-bootstrap";
import AdminLayout from '../../../layout/AdminLayout'
import Classes from "../../../asset/styles/AdminLaunchpad.module.css";
import { NavLink } from "react-router-dom";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { isEmpty } from "../../../lib/isEmpty";
import Usetoken from "../../../hooks/useToken";
import { EditSalestatus } from "../../../actions/adminAction";
import toast from "react-hot-toast";

const AdminLaunchpadList = ({ saleInfo, DBData, saleAddress, setStatusData }) => {

  const tokenHook = Usetoken()


  const [tab, setTab] = useState("sale");
  const [tokeninfo, setTokeninfo] = useState({})
  const [saleStatus, setsaleStatus] = useState(null);
  // const [status, setStatus] = useState(false);





  useEffect(() => {
    getTokenInfo()
    console.log("saleData", DBData, saleAddress?.publicKey.toString())
  }, [saleInfo?.splMint, DBData])




  const editsalestatus = async (saleAddress, data) => {
    console.log("call", saleAddress, data);

    let info = {
      presaleAddress: saleAddress,
      status: data
    }
    let result = await EditSalestatus(info);
    console.log("resultresult", result);
    if (result?.data?.status == true) {
      // setStatus(!status)
      setStatusData("")
    }


    if (data) {
      toast.success("Sale is Enabled")
    } else {
      toast.success("Private sale is Disabled")
    }




    console.log("editwhitelistStatus", result?.data?.status);

  }

  const getTokenInfo = async () => {
    console.log("tokeninfoooo", saleInfo, saleInfo?.splMint)
    if (!isEmpty(saleInfo?.splMint)) {
      // let tokenInfo = await tokenHook.getTokenbalance(walletdetail?.walletaddress,  saleInfo?.splMint)
      let tokenInfo = await tokenHook.getTokenNameSymbol(saleInfo?.splMint)
      console.log("tokenInfo", tokenInfo)
      setTokeninfo(tokenInfo)
    }
  }


  var percentage = saleInfo && parseInt(parseInt(saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / parseInt(saleInfo?.softCap / LAMPORTS_PER_SOL) * 100)
  var percentage_launch = saleInfo && parseInt(parseInt(saleInfo?.currencyRaised / LAMPORTS_PER_SOL) / parseInt(saleInfo?.hardCap / LAMPORTS_PER_SOL) * 100)

  return (
    <>
      <tr className={`${Classes.gradientBorder}`}>
        <td className="py-2">
          <div className={`${Classes.tokenDetails} d-flex justify-content-start align-items-center gap-2`} >
            <img
              src={DBData?.logo ? DBData?.logo : Images.coinCat}
              className={`${Classes.tableTokenImg} img-fluid`}
              alt=""
            />
            <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>
              {tokeninfo?.name ? tokeninfo?.name : 'Meme of Neko'}
            </p>
          </div>
        </td>
        <td>
          <p
            className={`${Classes.tableTokenName} poppins m-0 position-relative`}
          >
            {saleInfo?.isWhitelist == false ? "Launchpad" : "Private Sale"}
          </p>
        </td>
        <td>
          <p
            className={`${Classes.tableTokenName} poppins m-0 position-relative`}
          >
            {saleInfo?.softCap / LAMPORTS_PER_SOL}
          </p>
        </td>
        <td>
          <p
            className={`${Classes.tableTokenName} poppins m-0 position-relative`}
          >
            {saleInfo?.hardCap / LAMPORTS_PER_SOL}
          </p>
        </td>
        <td>
          <div className={`d-flex justify-content-start align-items-center gap-2 me-4`}>

            {isEmpty(DBData?.kyc) && isEmpty(DBData?.audit) && isEmpty(DBData?.DOXX) && isEmpty(DBData?.SAFU) ?
              <p className={`${Classes.tableTokenName} poppins m-0 position-relative`}>-</p> : ""
            }
            {!isEmpty(DBData?.kyc) &&
              <NavLink to={DBData?.kyc} target="_blank" className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                <button className={`${Classes.badgeBtns} text-uppercase orbitron border-0 outline-0 rounded-5 pointer`}>k</button>
              </NavLink>
            }
            {!isEmpty(DBData?.audit) &&
              <NavLink to={DBData?.audit} target="_blank" className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                <button className={`${Classes.badgeBtns} text-uppercase orbitron border-0 outline-0 rounded-5 pointer`}>a</button>
              </NavLink>
            }
            {!isEmpty(DBData?.DOXX) &&
              <NavLink to={DBData?.DOXX} target="_blank" className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                <button className={`${Classes.badgeBtns} text-uppercase orbitron border-0 outline-0 rounded-5 pointer`}>d</button>
              </NavLink>
            }
            {!isEmpty(DBData?.SAFU) &&
              <NavLink to={DBData?.SAFU} target="_blank" className={`cmnNavLink pointer ${Classes.indexTopper}`}>
                <button className={`${Classes.badgeBtns} text-uppercase orbitron border-0 outline-0 rounded-5 pointer`}>s</button>
              </NavLink>
            }

          </div>
        </td>
        <td>
          {saleInfo?.launchType == "Launchpad" || saleInfo?.launchType == "Private Sale" ?
            <div className={`${Classes.card__progressHolder}`}>
              <div
                className={`${Classes.card__progressBar} position-relative`}
              >
                <div
                  className={`${Classes.card__filledProgress}`}
                  style={{ width: `${saleInfo && percentage_launch > 100 ? 100 : parseInt((saleInfo?.currencyRaised) / (saleInfo?.hardCap) * 100)}%` }}
                ></div>
              </div>
              <p
                className={`${Classes.tableTokenHint} poppins m-0 position-relative text-center mt-2`}
              >
                {(parseInt(saleInfo?.currencyRaised)) / LAMPORTS_PER_SOL}/{(parseInt(saleInfo?.hardCap)) / LAMPORTS_PER_SOL}
              </p>
            </div>
            : saleInfo?.launchType == "FairLaunch" ?

              <div className={`${Classes.card__progressHolder}`}>
                <div
                  className={`${Classes.card__progressBar} position-relative`}
                >
                  <div
                    className={`${Classes.card__filledProgress}`}
                    style={{ width: `${saleInfo && percentage > 100 ? 100 : parseInt((saleInfo?.currencyRaised) / (saleInfo?.softCap) * 100)}%` }}
                  ></div>
                </div>
                <p
                  className={`${Classes.tableTokenHint} poppins m-0 position-relative text-center mt-2`}
                >
                  {(parseInt(saleInfo?.currencyRaised)) / LAMPORTS_PER_SOL}/{(parseInt(saleInfo?.softCap)) / LAMPORTS_PER_SOL}
                </p>
              </div>
              :
              <div className={`${Classes.card__progressHolder}`}>
                <div
                  className={`${Classes.card__progressBar} position-relative`}
                >
                  <div
                    className={`${Classes.card__filledProgress}`}
                    style={{ width: `${saleInfo && percentage_launch > 100 ? 100 : parseInt((saleInfo?.currencyRaised) / (saleInfo?.hardCap) * 100)}%` }}
                  ></div>
                </div>
                <p
                  className={`${Classes.tableTokenHint} poppins m-0 position-relative text-center mt-2`}
                >
                  {(parseInt(saleInfo?.currencyRaised)) / LAMPORTS_PER_SOL}/{(parseInt(saleInfo?.hardCap)) / LAMPORTS_PER_SOL}
                </p>
              </div>
          }
        </td>
        <td>
          <div
            className={`d-flex justify-content-start align-items-center gap-2`}
          >
            <NavLink to={DBData?.website} target="_blank" className={`cmnNavLink pointer ${Classes.indexTopper}`}>
              <button
                className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
              >
                <CiGlobe className={`${Classes.globeImg}`} />
              </button>
            </NavLink>
            <NavLink to={DBData?.twitter} target="_blank" className={`cmnNavLink pointer ${Classes.indexTopper}`}>
              <button
                className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
              >
                <FaXTwitter />
              </button>
            </NavLink>
            <NavLink to={DBData?.telegram} target="_blank" className={`cmnNavLink pointer ${Classes.indexTopper}`}>
              <button
                className={`${Classes.socialIconBtn} d-flex justify-content-center align-items-center pointer`}
              >
                <FaTelegramPlane />
              </button>
            </NavLink>
          </div>
        </td>
        <td>
          <p
            className={`${Classes.tableTokenName} poppins m-0 position-relative`}
          >
            {saleInfo && new Date(parseInt(saleInfo?.presaleStart)).toLocaleDateString() + "  "
              + new Date(parseInt(saleInfo?.presaleStart)).getHours() + ":" + new Date(parseInt(saleInfo?.presaleStart)).getMinutes() + ":"
              + new Date(parseInt(saleInfo?.presaleStart)).getSeconds()}
          </p>
        </td>
        <td>
          <p
            className={`${Classes.tableTokenName} poppins m-0 position-relative`}
          >
            {saleInfo && new Date(parseInt(saleInfo?.presaleEnd)).toLocaleDateString() + "  "
              + new Date(parseInt(saleInfo?.presaleEnd)).getHours() + ":" + new Date(parseInt(saleInfo?.presaleEnd)).getMinutes() + ":"
              + new Date(parseInt(saleInfo?.presaleEnd)).getSeconds()}
          </p>
        </td>
        <td>


          {console.log("DBData?.isActive", DBData?.isActive, DBData?.saleAddress)}

          {DBData?.isActive ?
            <button className={` ${Classes.tablePlainBtn}`} onClick={() => editsalestatus(DBData?.saleAddress, false)}>
              <p className={`${Classes.plainBtnText}`}>
                Disable
              </p>
            </button> : <button className={` ${Classes.tablePlainBtn}`} onClick={() => editsalestatus(DBData?.saleAddress, true)}>
              <p className={`${Classes.plainBtnText}`}>
                Enable
              </p>
            </button>
          }
        </td>
      </tr>
    </>
  )
}

export default AdminLaunchpadList
